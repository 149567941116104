import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { hot, setConfig } from 'react-hot-loader';
import { HelmetProvider } from 'react-helmet-async';

import 'antd/dist/antd.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/timezone/lib/css/blueprint-timezone.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';
import '@blueprintjs/core/lib/css/blueprint.css';

import './App.less';

type Props = {
  children?: React.ReactNode,
};

/**
     <ReduxProvider store={store}>
       <HelmetProvider context={context}>
         <StaticRouter location={req.url} context={context}>
           <AppRouter />
         </StaticRouter>
       </HelmetProvider>
     </ReduxProvider>
 */

/**
 <ReduxProvider store={store}>
     <HelmetProvider>
       <Router history={history}>
         <AppRouter />
       </Router>
     </HelmetProvider>
 </ReduxProvider>
 */

class App extends PureComponent<Props> {
  static propTypes = {
    children: PropTypes.element,
  };

  static defaultProps = {
    children: null,
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { children, context } = this.props;
    return (
      <HelmetProvider context={context}>
        {children ? React.Children.only(children) : undefined}
      </HelmetProvider>
    );
  }
}

setConfig({ logLevel: 'debug', reloadHooks: true });

export default hot(module)(App);
