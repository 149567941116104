import { mapValues, uniq, omit } from 'lodash';
import type { Group } from '../types/group';
import {
  LOAD_GROUP_REQUEST,
  LOAD_GROUP_FAILURE,
  LOAD_GROUP_SUCCESS,
  LOAD_GROUPS_REQUEST,
  LOAD_GROUPS_FAILURE,
  LOAD_GROUPS_SUCCESS,
  CREATE_GROUP_REQUEST,
  CREATE_GROUP_FAILURE,
  CREATE_GROUP_SUCCESS,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_FAILURE,
  DELETE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILURE,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS,
} from '../constants';

export type GroupsState = {
  group: Group,
  ids: string[],
  error?: any,
  entities: { [key: string]: Group },
  isFetching: boolean,
  isSubmitting: boolean,
};

// Initial State
const initialState: GroupsState = {
  ids: [],
  group: {},
  entities: {},
  isFetching: false,
  isSubmitting: false,
};

const reducer = (state = initialState, action): GroupsState => {
  switch (action.type) {
    case LOAD_GROUP_REQUEST:
      return { ...state, group: {}, isFetching: true };

    case CREATE_GROUP_SUCCESS:
    case UPDATE_GROUP_SUCCESS:
    case LOAD_GROUP_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...mapValues(action.payload.entities.groups, (obj, id) => ({
            ...obj,
            id,
          })),
        },
        ids: uniq([...state.ids, action.payload.result]),
        isFetching: false,
        isSubmitting: false,
      };

    case CREATE_GROUP_REQUEST:
    case DELETE_GROUP_REQUEST:
    case UPDATE_GROUP_REQUEST:
      return { ...state, error: null, isSubmitting: true };

    case LOAD_GROUPS_REQUEST:
      return { ...state, error: null, isFetching: true };

    case LOAD_GROUPS_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...mapValues(action.payload.entities.groups, (obj, id) => ({
            ...obj,
            id,
          })),
        },
        ids: uniq([...state.ids, ...action.payload.result]),
        isFetching: false,
      };

    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        entities: omit(state.entities, action.payload.uuid),
        ids: state.ids.filter((id) => id !== action.payload.uuid),
        isSubmitting: false,
      };

    case CREATE_GROUP_FAILURE:
    case DELETE_GROUP_FAILURE:
    case UPDATE_GROUP_FAILURE:
    case LOAD_GROUP_FAILURE:
    case LOAD_GROUPS_FAILURE:
      return {
        ...state,
        error: action.error,
        timestamp: action.timestamp,
        isFetching: false,
        isSubmitting: false,
      };

    default:
      return state;
  }
};

export default reducer;
