import * as Yup from 'yup';
import { schema } from 'normalizr';
import type { Role } from './role';

type GroupType = {
  uuid: string,
  name: string,
  description: string,
  type: string,
  avatar: string,
  isRestricted: boolean,
  created: string,
  updated: string,
  roles: Role[],
};

type UserType = {
  uuid: string,
  created: string,
  avatar: string,
  email: string,
  firstName: string,
  lastName: string,
  notify: any,
  options: any,
  roles: Role[],
  updated: Date,
  userName: string,
  userType: string,
  isAdmin: boolean,
  versions: any[],
  groups: GroupType[],
};

type Group = GroupType & { users: UserType[] };

export const userSchema = new schema.Entity(
  'users',
  {},
  {
    idAttribute: (user) => user.uuid.toLowerCase(),
  },
);

export const basicSettings = Yup.object()
  .noUnknown()
  .shape({
    firstName: Yup.string()
      .ensure()
      .nullable()
      .trim('cannot begin or end with spaces.'),
    lastName: Yup.string().ensure().nullable(),
    avatar: Yup.string()
      .trim()
      .url()
      .default(null)
      .transform(function (value: string | null | any) {
        if (this.isType(value)) {
          if (value === null || value.length === 0) return null;

          return (value.startsWith('/')
            ? new URL(value, window.location.origin).href
            : value
          ).replace(/(^(?:https?|ftp):\/\/)(localhost)/, '$1127.0.0.1');
        }
        return value;
      })
      .nullable(true),
  });

export const password = Yup.string()
  .required('Please enter a new password.')
  .min(7, 'minimum length is 7 characters.')
  .max(64, 'maximum length is 64 characters.')
  .strict(true)
  .trim('cannot begin or end with spaces.');

export const confirmationPassword = Yup.string()
  .oneOf(
    [Yup.ref('password'), null],
    'Confirmation password must match new password',
  )
  .required('Please enter a confirmation password.');

export type { UserType, Group };
export default basicSettings;
