import { omit, uniq } from 'lodash';
import {
  // LOAD_ROLES_REQUEST,
  // LOAD_ROLES_SUCCESS,
  LOAD_ROLE_REQUEST,
  LOAD_ROLE_SUCCESS,
  LOAD_ROLES_REQUEST,
  LOAD_ROLES_FAILURE,
  LOAD_ROLES_SUCCESS,
  CREATE_ROLE_REQUEST,
  CREATE_ROLE_FAILURE,
  CREATE_ROLE_SUCCESS,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_FAILURE,
  UPDATE_ROLE_SUCCESS,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_FAILURE,
  DELETE_ROLE_SUCCESS,
  SET_GROUP_FILTER,
  SET_PROJECT_FILTER,
} from '../constants';

import type { Role } from '../types/role';

export type RoleState = {
  role: Role,
  ids: string[],
  isFetching: boolean,
  isSubmitting: boolean,
  projectFilter: string | null,
  entities: { [key: string]: Role },
};

// Initial State
const initialState: RoleState = {
  role: {},
  ids: [],
  entities: {},
  isFetching: false,
  isSubmitting: false,
  projectFilter: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECT_FILTER:
      return { ...state, projectFilter: action.payload.projectUuid };

    case SET_GROUP_FILTER:
      return { ...state, projectFilter: action.payload.groupUuid };

    case LOAD_ROLES_REQUEST:
      return { ...state, isFetching: true };

    case LOAD_ROLE_REQUEST:
      return { ...state, role: {}, isFetching: true };

    case LOAD_ROLE_SUCCESS:
      return { ...state, role: action.role, isFetching: false };

    case LOAD_ROLES_SUCCESS:
      return {
        ...state,
        entities: { ...state.entities, ...action.payload.entities },
        ids: uniq(action.payload.ids),
        isFetching: false,
      };

    case LOAD_ROLES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    case CREATE_ROLE_REQUEST:
      return { ...state, isSubmitting: true };

    case CREATE_ROLE_FAILURE:
      return { ...state, error: action.error, isSubmitting: false };

    case CREATE_ROLE_SUCCESS:
      return {
        ...state,
        ids: [action.payload.uuid, ...state.ids],
        entities: { ...state.entities, ...action.payload.entities },
        isSubmitting: false,
      };

    case UPDATE_ROLE_REQUEST:
    case UPDATE_ROLE_FAILURE:
      return { ...state, isSubmitting: false, error: action.error };

    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        entities: { ...state.entities, ...action.payload.entities },
      };

    case DELETE_ROLE_REQUEST:
      return { ...state, isSubmitting: true };

    case DELETE_ROLE_FAILURE:
      return { ...state, isSubmitting: false, error: action.error };

    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        entities: omit(state.entities, action.payload.uuid),
        ids: state.ids.filter((id) => id !== action.payload.uuid),
      };

    default:
      return state;
  }
};

export default reducer;
