import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createEpicMiddleware, ofType } from 'redux-observable';
import {
  composeWithDevTools,
  devToolsEnhancer,
} from 'redux-devtools-extension/developmentOnly';
import { ajax } from 'rxjs/ajax';
import { BehaviorSubject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { SearchApi, reduxSearch } from 'redux-search';
import type { RootState } from 'common/reducers';
import { currentProjectFilter } from 'common/selectors/projects';
import * as H from 'history';
import type { AnyAction, Store } from 'redux';
import browserHistory from '../history';
import { rootEpic, rootReducer } from '../reducers';
import createLogger from './logger';
import { name, version } from '../../../package.json';

function indexByUuid(...args: string[]) {
  return ({ indexDocument, resources }) => {
    Object.keys(resources).forEach((uuid) => {
      args.map((arg) =>
        indexDocument(resources[uuid].uuid, resources[uuid][arg]),
      );
    });
  };
}

function configureStore(
  preloadedState,
  url = '/',
): { history: H.History, store: Store<RootState, AnyAction> } {
  const history = !process.env.BROWSER
    ? H.createMemoryHistory({
        initialEntries: [url],
      })
    : browserHistory;

  const sock$ = new BehaviorSubject(null);
  const epicMiddleware = createEpicMiddleware({
    dependencies: {
      sock$,
      // localStorage,
      getJSON: ajax.getJSON,
      currentProject: currentProjectFilter,

      defaultServer: '/nominode/websocket',
    },
  });

  const middleware = [thunk.withExtraArgument({ history }), epicMiddleware];

  const search = reduxSearch({
    resourceIndexes: {
      // tasks: indexByUuid('alias'),
      sequences: indexByUuid('alias'),
      groups: indexByUuid('name'),
      sharedConfigs: indexByUuid('alias'),
      sharedFields: indexByUuid('alias'),
      sharedObjects: indexByUuid('alias'),
      connections: indexByUuid('alias'),
      projects: indexByUuid('alias'),
      roles: indexByUuid('roleName'),
    },

    resourceSelector: (resourceName, state) => {
      switch (resourceName) {
        case 'connections':
          return state[resourceName].connections.entities;
        case 'sharedConfigs':
          return state.sharedObjects.configs.entities;
        case 'sharedFields':
          return state.sharedObjects.fields.entities;
        case 'sharedObjects':
          return state[resourceName].objects.entities;
        case 'tasks':
          return state[resourceName].entities.tasks;
        default:
          return state[resourceName].entities;
      }
    },

    searchApi: new SearchApi(),
  });

  let enhancer;

  if (__DEV__) {
    // eslint-disable-next-line no-unused-vars
    const reduxLogger = createLogger({
      diff: true,
      duration: false,
      collapsed: true,
    });

    // middleware.push(reduxLogger);

    // eslint-disable-next-line no-unused-vars
    const composeEnhancers = composeWithDevTools({
      name: `${name}@${version}`,
      trace: false,
    });

    enhancer = compose(
      applyMiddleware(...middleware),
      search,
      devToolsEnhancer({ name: `${name}@${version}`, trace: false }),
    );
  } else {
    enhancer = compose(applyMiddleware(...middleware), search);
  }

  const store = createStore(rootReducer, preloadedState, enhancer);
  const epic$ = new BehaviorSubject(rootEpic);
  const hotReloadingEpic = (action$, ...rest) =>
    epic$.pipe(
      mergeMap((epic) =>
        epic(action$, ...rest).pipe(
          takeUntil(action$.pipe(ofType('EPIC_END'))),
        ),
      ),
    );
  epicMiddleware.run(hotReloadingEpic);

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      import('../reducers')
        .then(({ rootReducer: nextRootReducer }) => {
          store.dispatch({ type: 'EPIC_END' });
          store.replaceReducer(nextRootReducer);
        })
        .catch(console.error);
    });
  }

  return { store, history };
}

export default configureStore;
