import { combineReducers } from 'redux';
import { remove } from 'lodash';
import { UserType } from '../types/user';
import {
  EDIT_USER_FAILURE,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USERS_REQUEST,
  LOAD_USERS_FAILURE,
  LOAD_USERS_SUCCESS,
  REGISTER_USER_FAILURE,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_CLEAR,
  REGISTER_USER_STEP,
  REGISTER_USER_EDIT,
  VALIDATE_USER_FAILURE,
} from '../constants';

export type RegisterState = {
  user: UserType,
  isRegistering: boolean,
  error?: string,
  timestamp?: string,
  formStep?: number,
  validationErrors: Object,
};

export type ManagementState = {
  user: UserType,
  data: UserType[],
  isFetching: boolean,
  isEditing: boolean,
  isDeleting: boolean,
};

// Initial State
const initialState: ManagementState = {
  user: {},
  data: [],
  isFetching: false,
  isEditing: false,
  isDeleting: false,
};

export const management = (state: ManagementState = initialState, action) => {
  switch (action.type) {
    case LOAD_USER_REQUEST:
      return { ...state, user: {}, isFetching: true };
    case LOAD_USER_SUCCESS:
      return { ...state, user: action.user, isFetching: false };
    case LOAD_USERS_REQUEST:
      return { ...state, isFetching: true };
    case LOAD_USERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        timestamp: new Date(),
        error: action.error,
      };
    case LOAD_USERS_SUCCESS:
      return { ...state, data: action.users, isFetching: false };
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        isEditing: false,
        user: {
          ...state.user,
          ...action.user,
          isEditing: false,
        },
      };
    case EDIT_USER_REQUEST:
      return { ...state, isEditing: true };
    case EDIT_USER_FAILURE:
      return {
        ...state,
        ...action,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        data: remove(state.data, (d) => d.uuid === action.user.uuid),
      };
    case DELETE_USER_REQUEST:
      return { ...state, isDeleting: true };
    case DELETE_USER_FAILURE:
      return Object.assign(state, {
        isDeleting: false,
        error: action.error,
        timestamp: action.timestamp,
      });
    default:
      return state;
  }
};

const initialRegisterState: RegisterState = {
  user: {
    uuid: 'USER-CREATE',
    email: '',
    userName: '',
    firstName: '',
    lastName: '',
    roles: [],
    groups: [],
  },
  isRegistering: false,
  formStep: 0,
};

export const userRegister = (
  state: RegisterState = initialRegisterState,
  action,
) => {
  switch (action.type) {
    case REGISTER_USER_FAILURE:
      return Object.assign(state, {
        error: action.error,
        timestamp: action.timestamp,
        isRegistering: false,
      });
    case REGISTER_USER_REQUEST:
      return Object.assign(state, {
        isRegistering: true,
      });
    case REGISTER_USER_STEP:
      return Object.assign(state, {
        formStep: action.formStep,
        user: Object.assign(state.user, action.user),
      });
    case REGISTER_USER_EDIT:
      return Object.assign(state, {
        user: Object.assign(action.user, state.user),
      });
    case VALIDATE_USER_FAILURE:
      return Object.assign(state, {
        validationErrors: action.errors,
      });
    case REGISTER_USER_SUCCESS:
      return Object.assign(state, {
        isRegistering: false,
        user: {},
      });
    case REGISTER_USER_CLEAR:
      return initialRegisterState;
    default:
      return state;
  }
};

export type UserManagementState = {
  manage: ManagementState,
  register: RegisterState,
};

export default combineReducers({
  manage: management,
  register: userRegister,
});
