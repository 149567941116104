import { schema } from 'normalizr';

export type Project = {
  uuid: string,
  alias: string,
  avatar: string,
  abbreviation: string,
  description: string,
  created?: string,
  updated?: string,
};

export const projectSchema = new schema.Entity(
  'projects',
  {},
  {
    idAttribute: (project) => project.uuid,
  },
);

export const arrayOfProjects = [projectSchema];
