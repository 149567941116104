import { omit } from 'lodash';
import {
  LOAD_DASHBOARDS_REQUEST,
  LOAD_DASHBOARD_ITEMS_FAILURE,
  LOAD_DASHBOARDS_FAILURE,
  LOAD_DASHBOARDS_SUCCESS,
  LOAD_DASHBOARD_ITEMS_SUCCESS,
  LOAD_DASHBOARD_ITEMS_REQUEST,
  SET_CURRENT_DASHBOARD,
  CREATE_DASHBOARD_SUCCESS,
  CREATE_DASHBOARD_REQUEST,
  CREATE_DASHBOARD_FAILURE,
  CREATE_DASHBOARD_ITEM_SUCCESS,
  CREATE_DASHBOARD_ITEM_FAILURE,
  CREATE_DASHBOARD_ITEM_REQUEST,
  DELETE_DASHBOARD_ITEM_SUCCESS,
  DELETE_DASHBOARD_ITEM_FAILURE,
  DELETE_DASHBOARD_ITEM_REQUEST,
  UPDATE_DASHBOARD_ITEM_SUCCESS,
  UPDATE_DASHBOARD_ITEM_FAILURE,
  UPDATE_DASHBOARD_ITEM_REQUEST,
  DELETE_DASHBOARD_SUCCESS,
  DELETE_DASHBOARD_FAILURE,
  DELETE_DASHBOARD_REQUEST,
  SET_CURRENT_PROJECT,
} from '../constants';

export type DashboardsState = {
  dashboards: { [key: string]: Object },
  dashboardItems: { [key: string]: Object },
  error?: any,
  isFetchingDashboards: boolean,
  isCreatingDashboard: boolean,
  createDashboardError: String,
  currentDashboard: string,
  createDashboardItemError: String,
  creatingDashboardItem: Boolean,
};

// Initial State
const initialState: DashboardsState = {
  dashboards: {},
  dashboardItems: {},
  isFetchingDashboards: false,
  errorFetchingDashboards: '',
  error: '',
  currentDashboard: '',
  isCreatingDashboard: false,
  createDashboardError: '',
  createDashboardItemError: '',
  creatingDashboardItem: false,
};

const reducer = (state = initialState, action): DashboardsState => {
  switch (action.type) {
    case SET_CURRENT_PROJECT:
      return initialState;
    case DELETE_DASHBOARD_FAILURE:
      return {
        ...state,
        isDeletingDashboard: false,
        deleteDashboardError: action.error,
      };
    case DELETE_DASHBOARD_REQUEST:
      return {
        ...state,
        isDeletingDashboard: true,
        deleteDashboardError: '',
      };
    case DELETE_DASHBOARD_SUCCESS:
      return {
        ...state,
        isDeletingDashboard: false,
        dashboards: omit(state.dashboards, action.uuid),
        dashboardItems: omit(state.dashboardItems, action.uuid),
      };
    case SET_CURRENT_DASHBOARD:
      return {
        ...state,
        currentDashboard: action.payload,
      };
    case LOAD_DASHBOARDS_REQUEST:
      return {
        ...state,
        isFetchingDashboards: true,
      };
    case LOAD_DASHBOARDS_FAILURE:
      return {
        ...state,
        isFetchingDashboards: false,
        error: action.error,
        timestamp: action.timestamp,
      };

    case LOAD_DASHBOARDS_SUCCESS:
      return {
        ...state,
        dashboards: action.dashboards,
        isFetchingDashboards: false,
      };
    case LOAD_DASHBOARD_ITEMS_REQUEST:
      return {
        ...state,
        dashboardItems: {
          ...state.dashboardItems,
          [action.uuid]: {
            ...state.dashboardItems[action.uuid],
            isFetching: true,
            error: '',
          },
        },
      };
    case LOAD_DASHBOARD_ITEMS_SUCCESS:
      return {
        ...state,
        dashboardItems: {
          ...state.dashboardItems,
          [action.uuid]: {
            items: action.payload,
            isFetching: false,
            error: '',
          },
        },
      };
    case LOAD_DASHBOARD_ITEMS_FAILURE:
      return {
        ...state,
        dashboardItems: {
          ...state.dashboardItems,
          [action.uuid]: {
            ...state.dashboardItems[action.uuid],
            isFetching: false,
            error: action.error,
          },
        },
      };
    case CREATE_DASHBOARD_REQUEST:
      return {
        ...state,
        isCreatingDashboard: true,
      };
    case CREATE_DASHBOARD_FAILURE:
      return {
        ...state,
        createDashboardError: action.error,
        isCreatingDashboard: false,
      };
    case CREATE_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboards: {
          ...state.dashboards,
          [action.dashboard.uuid]: action.dashboard,
        },
        createDashboardError: '',
        isCreatingDashboard: false,
      };
    case CREATE_DASHBOARD_ITEM_SUCCESS:
      return {
        ...state,
        dashboardItems: {
          ...state.dashboardItems,
          [action.uuid]: {
            items: {
              ...state.dashboardItems[action.uuid].items,
              [action.payload.uuid]: action.payload,
            },
          },
        },
        createDashboardItemError: '',
        isCreatingDashboardItem: false,
      };
    case CREATE_DASHBOARD_ITEM_FAILURE:
      return {
        ...state,
        createDashboardItemError: action.error,
        isCreatingDashboardItem: false,
      };
    case CREATE_DASHBOARD_ITEM_REQUEST:
      return {
        ...state,
        createDashboardItemError: '',
        isCreatingDashboardItem: true,
      };
    case DELETE_DASHBOARD_ITEM_SUCCESS:
      return {
        ...state,
        dashboardItems: {
          ...state.dashboardItems,
          [action.dashboardUuid]: {
            items: omit(
              state.dashboardItems[action.dashboardUuid].items,
              action.uuid,
            ),
          },
        },
      };
    case DELETE_DASHBOARD_ITEM_FAILURE:
      return {
        ...state,
        deleteDashboardItemError: action.error,
        isDeletingDashboardItem: false,
      };
    case DELETE_DASHBOARD_ITEM_REQUEST:
      return {
        ...state,
        deleteDashboardItemError: '',
        isDeletingDashboardItem: true,
      };
    case UPDATE_DASHBOARD_ITEM_SUCCESS:
      return {
        ...state,
        dashboardItems: {
          ...state.dashboardItems,
          [action.payload.dashboardUuid]: {
            items: {
              ...state.dashboardItems[action.payload.dashboardUuid].items,
              [action.payload.uuid]: action.payload,
            },
          },
        },
      };
    case UPDATE_DASHBOARD_ITEM_FAILURE:
      return {
        ...state,
        deleteDashboardItemError: action.error,
        isDeletingDashboardItem: false,
      };
    case UPDATE_DASHBOARD_ITEM_REQUEST:
      return {
        ...state,
        deleteDashboardItemError: '',
        isDeletingDashboardItem: true,
      };
    default:
      return state;
  }
};

// Export Reducer
export default reducer;
