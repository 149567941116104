import { schema } from 'normalizr';
import { mapValues } from 'lodash';
import { getDefaultActionParams } from 'common/util/engine';

import type { ExecutionState, TaskExecution } from './executions';
import type { Parameters } from './parameters';

type EngineModel = {
  uuid: string,
  alias: string,
  description: string,
  created: Date,
  updated: Date,
  categories: any,
  actions: { [key: string]: Parameters[] } | any,
  mdFiles: any,
  icons?: any,
  engineUuid?: string,
  defaultValues?: any,
};

type EngineType = {
  uuid: string,
  disabled: boolean,
  defaultChannel: string,
  defaultModel: EngineModel | string,
  models: EngineModel[] | string[],
  channels?: string[],
  isFetching?: boolean,
};

interface ChannelSchema {
  [key: string]: string;
}

type TaskType = {
  uuid: string,
  engineUuid: string,
  alias: string,
  concurrency: number,
  description: string,
  actions: string,
  parameters: any,
  notify: any,
  options: any,
  status: ExecutionState,
  created: string,
  updated: string,
  engine: EngineType,
  engineAlias: string,
  lastExecution: TaskExecution,
  projectUuid: string,
  lastExecutionUuid?: string,
  lastSuccessfulExecutionUuid?: string,
};

type EngineTypeEntities = { [key: string]: EngineType };
type ModelEntities = { [key: string]: EngineModel };
type ChannelEntities = { [key: string]: ChannelSchema };

type EngineEntities = {
  models: ModelEntities,
  channels: ChannelEntities,
  engines: EngineTypeEntities,
};

const modelSchema = new schema.Entity<EngineModel>(
  'models',
  {},
  {
    idAttribute: 'uuid',
    processStrategy: (value: EngineModel, parent: EngineType) => {
      return {
        ...value,
        engineUuid: parent.uuid,
        categories: value.categories || [],
        tags: (value.categories || []).map(({ name }) => name),
        defaultValues: mapValues(value.actions, ({ parameters }) => {
          return getDefaultActionParams(parameters);
        }),
      };
    },
  },
);

const engineSchema = new schema.Entity<EngineModel>(
  'engines',
  {
    models: [modelSchema],
    // channels: [channelSchema],
    defaultModel: modelSchema,
  },
  {
    idAttribute: 'uuid',
    processStrategy: (value: EngineType) => {
      return {
        ...value,
        // channels: value.models,
        channels: value.models.map(({ channel }) => channel),
        channelsMap: Object.fromEntries(
          value.models.map(({ channel, uuid }) => [channel, uuid]),
        ),
        defaultModel: value.models.find(({ channel }) => {
          return channel === value.defaultChannel;
        }),
      };
    },
  },
);

const taskSchema = new schema.Entity(
  'tasks',
  {
    engine: modelSchema,
  },
  {
    idAttribute: 'uuid',
  },
);

const arrayOfTasks = [taskSchema];
const arrayOfEngines = [engineSchema];

export type { EngineModel, EngineType, EngineEntities, TaskType };
export { arrayOfEngines, engineSchema, arrayOfTasks, modelSchema, taskSchema };
