import type { Action } from 'redux';
import { from, of, timer } from 'rxjs';
import { ajax } from 'rxjs/ajax';

import {
  catchError,
  filter,
  finalize,
  map,
  mapTo,
  mergeMap,
  retryWhen,
  scan,
  switchMap,
  switchMapTo,
  takeUntil,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import type { ActionsObservable } from 'redux-observable';
import Uuid from '../util/uuid';
import { camelCaseKeys } from '../util/strings';
import { POLL_END, POLL_ERROR, POLL_RECEIVED, POLL_START } from '../constants';

type PollOptionsType = {
  constantWait: number | null,
  exponential: number,
  interval: number,
  maxAttempts: number,
  randRange: Array<number>,
  startOffset: number,
  strategy: 'exponential' | 'random' | 'linear' | string,
};

const defaultOptions: PollOptionsType = {
  constantWait: null,
  exponential: 1000,
  interval: 1000,
  maxAttempts: 9,
  randRange: [1000, 10000],
  startOffset: 0,
  strategy: 'linear',
};

function getStrategyDelay(errorCount: number, options: PollOptionsType) {
  const { constantWait, exponential, interval, randRange, strategy } = options;

  const [min, max] = randRange;
  const range = max - min;
  switch (strategy) {
    case 'exponential':
      return 2 ** (errorCount - 1) * exponential;

    case 'random':
      return Math.floor(Math.random() * range) + min;

    case 'linear':
      return constantWait || interval;

    default:
      console.warn('Retry strategy not chosen. Defaulting to "linear"');
      return constantWait || interval;
  }
}

function getRequestObservable(request, pollId) {
  if (typeof request === 'string') {
    return ajax.getJSON(request).pipe(
      map((data) => ({
        payload: {
          pollId: pollId || Uuid.uuidFast(),
          ...camelCaseKeys(data),
        },
      })),
    );
  }
  return from(request()).pipe(
    mapTo({ payload: { pollId: pollId || Uuid.uuidFast() } }),
  );
}

interface WrappedPromise {
  (): Promise;
}
interface PollStartAction extends Action {
  payload: { options: PollOptionsType, request: WrappedPromise | string };
}

interface PollEndAction extends Action {
  payload: { pollId?: string };
}

const pollEpic = (action$: ActionsObservable /* state$: StateObservable */) => {
  let pollCount = 0;
  return action$.pipe(
    ofType(POLL_START),
    mergeMap((action: PollStartAction) => {
      pollCount += 1;
      const pollId = Uuid.uuidFast();
      const { options, request } = action.payload || {};
      const pollOptions = {
        ...defaultOptions,
        ...(options || {}),
      };

      const { interval, maxAttempts, startOffset } = pollOptions;
      const attempts = maxAttempts === -1 ? Infinity : maxAttempts;

      const onPollEnd = action$.ofType(POLL_END).pipe(
        filter(({ payload }: PollEndAction) => {
          if (payload.pollId) {
            return payload.pollId === pollId;
          }
          return true;
        }),
      );

      return timer(startOffset, interval)
        .pipe(switchMapTo(getRequestObservable(request, pollId)))
        .pipe(
          retryWhen((errors$) =>
            errors$.pipe(
              scan(
                ({ errorCount }, error) => ({
                  error,
                  errorCount: errorCount + 1,
                }),
                { errorCount: 0, error: null },
              ),
              switchMap(({ error, errorCount }) => {
                // console.warn(`errors fetching: ${errorCount}`);
                if (errorCount >= attempts) throw error;
                const waitStrategy = getStrategyDelay(errorCount, pollOptions);
                return timer(waitStrategy, null);
              }),
            ),
          ),
          catchError(({ response }) => of({ error: response })),
        )
        .pipe(
          map((value) => {
            if (value.error) {
              console.warn(`polling error: "${value.error.status}"`);
              return { type: POLL_ERROR, ...value };
            }
            return { type: POLL_RECEIVED, ...value };
          }),

          // TODO: filter out single endpoints
          takeUntil(onPollEnd),
          finalize(() => {
            pollCount -= 1;
            console.info(`Polling Ended. Polling ${pollCount} endpoints.`);
          }),
        );
    }),
  );
};

export const apiPollEpic = (
  action$: ActionsObservable /* state$: StateObservable */,
) => {
  let pollCount = 0;
  return action$.pipe(
    ofType('API_STATUS_START'),
    switchMap((action: PollStartAction) => {
      const { options } = action.payload || {};
      const pollOptions = {
        ...defaultOptions,
        ...{ maxAttempts: -1, interval: 15000 },
        ...(options || {}),
      };

      const { interval, /* maxAttempts, */ startOffset } = pollOptions;
      // const attempts = maxAttempts === -1 ? Infinity : maxAttempts;

      return timer(startOffset, interval)
        .pipe(
          switchMapTo(
            ajax.getJSON('/api/v2/ready').pipe(
              map((data) => ({
                ...camelCaseKeys(data),
              })),
              catchError(({ response, xhr }) =>
                of({
                  hasFetched: true,
                  error: {
                    ...response,
                    status: xhr.statusText,
                    code: xhr.status,
                  },
                }),
              ),
            ),
          ),
        )
        .pipe(
          map((value) => {
            if (value.error) {
              console.warn(`polling error: "${value.error.status}"`);
              return { type: 'API_STATUS_ERROR', ...value };
            }
            return {
              type: 'API_STATUS_RECEIVED',
              hasFetched: true,
              payload: {
                ...value,
                initialized: value.initialized,
              },
            };
          }),

          // TODO: filter out single endpoints
          takeUntil(
            action$.ofType('API_STATUS_END'),
            finalize(() => {
              pollCount -= 1;
              console.info(`Polling Ended. Polling ${pollCount} endpoints.`);
            }),
          ),
        );
    }),
  );
};

export default pollEpic;
