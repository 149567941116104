import { combineReducers } from 'redux';
import type { ExecutionType, LogType } from '../types/executions';
import {
  EXECUTE_TASK_REQUEST,
  EXECUTE_TASK_FAILURE,
  EXECUTE_TASK_SUCCESS,
  LOAD_EXECUTIONS_REQUEST,
  LOAD_EXECUTIONS_SUCCESS,
  KILL_TASK_REQUEST,
  KILL_TASK_FAILURE,
  KILL_TASK_SUCCESS,
  RESET_EXECUTION_FILTERS,
  LOAD_EXECUTION_SUCCESS,
  LOAD_EXECUTION_FAILURE,
  LOAD_EXECUTION_REQUEST,
  LOAD_EXECUTION_RESULT_REQUEST,
  LOAD_EXECUTION_RESULT_FAILURE,
  LOAD_EXECUTION_RESULT_SUCCESS,
  LOAD_EXECUTION_STATS_PLOT_REQUEST,
  LOAD_EXECUTION_STATS_PLOT_SUCCESS,
  LOAD_EXECUTION_STATS_PLOT_FAILURE,
  LOAD_EXECUTIONS_FAILURE,
} from '../constants';

type InitialState = {
  execution: Map,
  executionResults: Map,
  statsPlots: any,
  taskId: string,
  data: Array<any>,
  logLines: Array<LogType>,
  latestLog: number,
  isFetching: boolean,
  requestingExecution: any,
  logSource: EventSource | null,
  pendingKillRequests: any,
};

type InitialListState = {
  data: Array<ExecutionType>,
  isFetching: boolean,
  filterSettings: any,
  isPolling: boolean,
  metaData: {
    rows: number,
    total: number,
    perPage: number,
  },
};

export type ExecutionsState = {
  execute: InitialState,
  paginatingList: InitialListState,
};

// Initial State
const initialState: InitialState = {
  execution: {},
  executionResults: {},
  statsPlots: {},
  taskId: '',
  data: [],
  logLines: [],
  latestLog: 0,
  isFetching: false,
  requestingExecution: {},
  logSource: null,
  pendingKillRequests: {},
};

const initialListState: InitialListState = {
  data: [],
  isFetching: false,
  filterSettings: {},
  isPolling: false,
  metaData: {
    rows: 0,
    total: 0,
    perPage: 0,
  },
};

const execute = (state: InitialState = initialState, action) => {
  switch (action.type) {
    case LOAD_EXECUTION_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case LOAD_EXECUTION_FAILURE:
      return {
        ...state,
        error: action.error,
        timestamp: action.timestamp,
        isFetching: false,
      };

    case LOAD_EXECUTION_SUCCESS:
      return {
        ...state,
        execution: { ...state.execution, ...action.execution },
        isFetching: false,
      };
    case LOAD_EXECUTION_RESULT_REQUEST:
      return {
        ...state,
        executionResults: {
          ...state.executionResults,
          [action.executionUuid]: { isFetching: true },
        },
      };
    case LOAD_EXECUTION_RESULT_FAILURE:
      return {
        ...state,
        executionResults: {
          ...state.executionResults,
          [action.executionUuid]: {
            error: action.error,
            isFetching: false,
          },
        },
      };

    case LOAD_EXECUTION_RESULT_SUCCESS:
      return {
        ...state,
        executionResults: {
          ...state.executionResults,
          [action.executionUuid]: {
            resultType: action.executionResult.result_type,
            plot: action.executionResult.result,
            isFetching: false,
          },
        },
      };

    case LOAD_EXECUTION_STATS_PLOT_REQUEST:
      return {
        ...state,
        statsPlots: {
          ...state.statsPlots,
          [action.executionUuid]: {
            isFetching: true,
          },
        },
      };

    case LOAD_EXECUTION_STATS_PLOT_FAILURE:
      return {
        ...state,
        statsPlots: {
          ...state.statsPlots,
          [action.executionUuid]: {
            error: action.error,
            isFetching: false,
          },
        },
      };

    case LOAD_EXECUTION_STATS_PLOT_SUCCESS:
      return {
        ...state,
        statsPlots: {
          ...state.statsPlots,
          [action.executionUuid]: {
            plot: action.statsPlot.result,
            isFetching: false,
          },
        },
      };

    case EXECUTE_TASK_REQUEST:
      return {
        ...state,
        taskId: action.taskId,
        requestingExecution: {
          ...state.requestingExecution,
          [action.payload]: true,
        },
      };

    case EXECUTE_TASK_FAILURE:
      return {
        ...state,
        error: action.error,
        timestamp: action.timestamp,
        requestingExecution: {
          ...state.requestingExecution,
          [action.payload]: false,
        },
      };

    case EXECUTE_TASK_SUCCESS:
      return {
        ...state,
        execution: action.payload.execution,
        requestingExecution: {
          ...state.requestingExecution,
          [action.payload.taskId]: false,
        },
      };

    case KILL_TASK_REQUEST:
      return {
        ...state,
        pendingKillRequests: {
          ...state.pendingKillRequests,
          [action.executionId]: true,
        },
      };

    case KILL_TASK_FAILURE:
      return {
        ...state,
        error: action.error,
        timestamp: action.timestamp,
        pendingKillRequests: {
          ...state.pendingKillRequests,
          [action.executionId]: false,
        },
      };

    case KILL_TASK_SUCCESS:
      return {
        ...state,
        pendingKillRequests: {
          ...state.pendingKillRequests,
          [action.killedTask.uuid]: false,
        },
      };

    default:
      return state;
  }
};

const paginatingList = (state: InitialListState = initialListState, action) => {
  switch (action.type) {
    case 'SET_IS_POLLING': {
      return { ...state, isPolling: action.payload };
    }

    case LOAD_EXECUTIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        filterSettings: {
          ...state.filterSettings,
          ...action.filterSettings,
        },
      };

    case LOAD_EXECUTIONS_FAILURE:
      return {
        ...state,
        isPolling: false,
        isFetching: false,
        error: action.error,
      };

    case LOAD_EXECUTIONS_SUCCESS:
      return {
        ...state,
        isPolling: false,
        isFetching: false,
        data: action.executions,
        metaData: action.metaData,
        filterSettings: {
          ...state.filterSettings,
          ...action.filterSettings,
        },
      };

    case RESET_EXECUTION_FILTERS:
      return {
        ...state,
        data: [],
        filterSettings: {},
      };

    case KILL_TASK_SUCCESS:
      return {
        ...state,
        data: state.data.map((exe) => {
          if (exe.uuid === action.killedTask.uuid) {
            return action.killedTask;
          }
          return exe;
        }),
      };

    default:
      return state;
  }
};

export default combineReducers({ execute, paginatingList });
