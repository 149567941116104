import Uuid from 'common/util/uuid';
import type { Reducer } from 'redux';
import { denormalize } from 'normalizr';
import { engineSchema } from 'common/types/engine';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { uniqBy } from 'lodash';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { createRxFetch } from 'common/util/createFetch';
import { TASK_DETAIL_SUCCESS } from '../constants';
import * as actionTypes from '../constants/engines';
import type { Action } from '../store/types';
import type { EngineEntities, EngineType } from '../types/engine';

type EngineState = {
  data: EngineType[],
  engine: EngineType,
  ids: string[],
  defaultValues: any,
  isFetching: boolean,
  categoryFilter: string[],
  entities: EngineEntities,
  categories: { name: string }[],
};

// Initial State
const initialState = {
  data: [],
  engine: {},
  ids: [],
  entities: {
    models: {},
    engines: {},
    channels: {},
  },
  defaultValues: {},
  isFetching: false,
  categories: [],
  categoryFilter: [],
  parameterCategories: [],
  parameterCategoryFilter: [],
};

const reducer: Reducer<EngineState, Action<any>> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.LOAD_DEFAULT_ACTION_VALUES:
      return { ...state, defaultValues: action.payload || {} };

    case actionTypes.LOAD_ENGINE_REQUEST:
      return {
        ...state,
        entities: {
          ...state.entities,
          engines: {
            ...state.entities.engines,
            [action.payload]: {
              ...state.entities.engines[action.payload],
              isFetching: true,
            },
          },
        },
      };

    case actionTypes.LOAD_ENGINE_FAILURE:
      return {
        ...state,
        error: action.error,
        timestamp: action.timestamp,
        entities: {
          ...state.entities,
          engines: {
            ...state.entities.engines,
            [action.payload]: {
              ...state.entities.engines[action.payload],
              isFetching: false,
            },
          },
        },
      };

    case actionTypes.LOAD_ENGINE_SUCCESS:
      return {
        ...state,
        entities: {
          ...action.payload.entities,
          engines: {
            ...state.entities.engines,
            [action.payload.result]: {
              ...state.entities.engines[action.payload.result],
              ...action.payload.entities.engines[action.payload.result],
              isFetching: false,
            },
          },
          models: {
            ...state.entities.models,
            ...action.payload.entities.models,
          },
        },
      };

    case actionTypes.ENGINE_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: uniqBy(
          [...state.categories, ...action.payload],
          ({ name }) => name,
        ),
      };
    case actionTypes.PARAMETER_CATEGORIES_SUCCESS:
      return {
        ...state,
        parameterCategories: uniqBy(
          [...state.parameterCategories, ...action.payload],
          ({ name }) => name,
        ),
      };

    case actionTypes.CLEAR_CATEGORY_FILTER:
      return { ...state, categoryFilter: [] };

    case actionTypes.SET_CATEGORY_FILTER:
      return { ...state, categoryFilter: action.payload };

    case actionTypes.CLEAR_PARAMETER_CATEGORY_FILTER:
      return { ...state, parameterCategoryFilter: [] };

    case actionTypes.SET_PARAMETER_CATEGORY_FILTER:
      return { ...state, parameterCategoryFilter: action.payload };

    case actionTypes.LOAD_ENGINES_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.LOAD_ENGINES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        timestamp: action.timestamp,
      };

    case actionTypes.LOAD_ENGINES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        data: action.payload.data.map((engine) => ({
          ...engine,
          models: engine.models.map((model) => ({
            ...model,
            categories: model.categories
              ? model.categories.map(({ name }) => name)
              : [],
          })),
        })),

        isFetching: false,
      };

    case actionTypes.LOAD_ENGINE_TYPE_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.LOAD_ENGINE_TYPE_SUCCESS:
      return { ...state, isFetching: false, engineTypes: action.engineTypes };

    case TASK_DETAIL_SUCCESS:
      return {
        ...state,
        engine: denormalize(
          action.payload.engineUuid,
          engineSchema,
          state.entities,
        ),
      };

    default:
      return state;
  }
};

function fetchEngineCategoriesEpic(action$) {
  return action$.pipe(
    ofType(actionTypes.FETCH_ENGINE_CATEGORIES),
    mergeMap(() => {
      return createRxFetch({
        method: 'get',
        url: '/api/v2/engine-categories',
      }).pipe(
        map(({ data: { results } }) => {
          return {
            type: actionTypes.ENGINE_CATEGORIES_SUCCESS,
            payload: results.map((r) => ({ ...r, id: Uuid.uuidFast() })),
          };
        }),
        takeUntil(
          action$.pipe(ofType(actionTypes.FETCH_ENGINE_CATEGORIES_CANCELLED)),
        ),
        catchError((error) => {
          return of({
            type: actionTypes.FETCH_ENGINE_CATEGORIES_REJECTED,
            payload: error.xhr.response,
            error: true,
          });
        }),
      );
    }),
  );
}

function fetchParameterCategoriesEpic(action$) {
  return action$.pipe(
    ofType(actionTypes.FETCH_PARAMETER_CATEGORIES),
    mergeMap(() => {
      return createRxFetch({
        method: 'get',
        url: '/api/v2/parameter-categories',
      }).pipe(
        map(({ data: { results } }) => {
          return {
            type: actionTypes.PARAMETER_CATEGORIES_SUCCESS,
            payload: results.map((r) => ({ ...r, id: Uuid.uuidFast() })),
          };
        }),
        takeUntil(
          action$.pipe(
            ofType(actionTypes.FETCH_PARAMETER_CATEGORIES_CANCELLED),
          ),
        ),
        catchError((error) =>
          of({
            type: actionTypes.FETCH_PARAMETER_CATEGORIES_REJECTED,
            payload: error,
            error: true,
          }),
        ),
      );
    }),
  );
}

export { fetchEngineCategoriesEpic, fetchParameterCategoriesEpic };
export type { EngineState };
export default reducer;
