import { combineReducers } from 'redux';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import type { AsyncThunk, EntityState } from '@reduxjs/toolkit';

import { callApi } from 'common/util/createFetch';
import {
  EDIT_CONFIG_FAILURE,
  EDIT_CONFIG_REQUEST,
  EDIT_CONFIG_SUCCESS,
  LOAD_CONFIG_FAILURE,
  LOAD_CONFIG_REQUEST,
  LOAD_CONFIG_SUCCESS,
  LOAD_VERSIONS_FAILURE,
  LOAD_VERSIONS_REQUEST,
  LOAD_VERSIONS_SUCCESS,
} from '../constants/index';

type SystemLock = {
  name: string,
};
type SystemLockState = EntityState<SystemLock> & {
  isFetching: boolean,
  isDeleting: boolean,
  loading: boolean,
  error: null | Any,
};
const initialVersionState = {
  availableVersions: {},
  currentVersions: {},
  hostDockerDir: '',
  isFetching: false,
  error: null,
};

export const versions = (state = initialVersionState, action) => {
  switch (action.type) {
    case LOAD_VERSIONS_SUCCESS:
      return {
        ...state,
        availableVersions: action.versions.availableVersions,
        currentVersions: action.versions.currentVersion,
        hostDockerDir: action.versions.hostDockerDir,
        isFetching: false,
      };
    case LOAD_VERSIONS_REQUEST:
      return { ...state, isFetching: true };
    case LOAD_VERSIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        timestamp: action.timestamp,
      };
    default:
      return state;
  }
};

// type appConfig = {
//   key: string,
//   config: {
//     description: string,
//     value: any,
//   },
// };

const initialConfigState = {
  settings: [],
  isFetching: false,
  error: null,
  isEditing: false,
};

export const appConfig = (state = initialConfigState, action) => {
  switch (action.type) {
    case LOAD_CONFIG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        settings: action.appConfig,
      };
    case LOAD_CONFIG_REQUEST:
      return { ...state, isFetching: true };
    case LOAD_CONFIG_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        timestamp: action.timestamp,
      };
    case EDIT_CONFIG_SUCCESS:
      return {
        ...state,
        isEditing: false,
        settings: state.settings.map((config) => {
          if (action.config.key === config.key) {
            return {
              ...config,
              ...action.config,
            };
          }
          return config;
        }),
      };
    case EDIT_CONFIG_REQUEST:
      return { ...state, isEditing: true };
    case EDIT_CONFIG_FAILURE:
      return {
        ...state,
        settings: state.settings,
        isEditing: false,
        error: action.error,
        timestamp: action.timestamp,
      };
    default:
      return state;
  }
};
const fetchSystemLocksByName: AsyncThunk = createAsyncThunk(
  'systemLocks/fetchByName',
  async (arg, thunkAPI) => {
    const { name } = arg;
    const { signal, rejectWithValue } = thunkAPI;
    try {
      const results = await callApi(
        `/api/v2/admin/systemlock/${name}`,
        'GET',
        undefined,
        { signal },
      );

      if (results.error)
        return rejectWithValue(results.errorData || results.error);

      return results;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
const createSystemLockByName: AsyncThunk = createAsyncThunk(
  'systemLocks/createByName',
  async (arg, thunkAPI) => {
    const { name } = arg;
    const { signal, rejectWithValue } = thunkAPI;
    try {
      const results = await callApi(
        `/api/v2/admin/systemlock/${name}`,
        'POST',
        undefined,
        { signal },
      );

      if (results.error)
        return rejectWithValue(results.errorData || results.error);

      return results;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const deleteSystemLockByName: AsyncThunk = createAsyncThunk(
  'systemLocks/deleteByName',
  async (arg, thunkAPI) => {
    const { name } = arg;
    const { signal, rejectWithValue } = thunkAPI;
    try {
      const results = await callApi(
        `/api/v2/admin/systemlock/${name}`,
        'DELETE',
        undefined,
        { signal },
      );

      if (results.error)
        return rejectWithValue(results.errorData || results.error);

      return results;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const systemLocksAdapter = createEntityAdapter<SystemLock>({
  selectId: (systemLock) => systemLock.name,
});
const initialSystemLockState: SystemLockState = systemLocksAdapter.getInitialState(
  { loading: false, isFetching: false, isDeleting: false, error: null },
);

const systemLockSlice = createSlice({
  name: 'systemlocks',
  initialState: initialSystemLockState,
  extraReducers: (builder) => {
    builder.addCase(fetchSystemLocksByName.pending, (draft) => {
      if (!draft.loading) {
        draft.loading = true;
        draft.error = null;
      }
    });
    builder.addCase(fetchSystemLocksByName.fulfilled, (draft, action) => {
      if (draft.loading) {
        systemLocksAdapter.addOne(draft, action.payload);
        draft.loading = false;
        draft.error = null;
      }
    });
    builder.addCase(fetchSystemLocksByName.rejected, (draft) => {
      if (draft.loading) {
        draft.loading = false;
        draft.error = null;
      }
    });
    builder.addCase(createSystemLockByName.pending, (draft) => {
      if (!draft.loading) {
        draft.loading = true;
        draft.error = null;
      }
    });
    builder.addCase(createSystemLockByName.fulfilled, (draft, action) => {
      if (draft.loading) {
        systemLocksAdapter.addOne(draft, action.payload);
        draft.loading = false;
        draft.error = null;
      }
    });
    builder.addCase(createSystemLockByName.rejected, (draft) => {
      if (draft.loading) {
        draft.loading = false;
        draft.error = null;
      }
    });
    builder.addCase(deleteSystemLockByName.pending, (draft) => {
      if (!draft.loading) {
        draft.loading = true;
        draft.error = null;
      }
    });
    builder.addCase(deleteSystemLockByName.fulfilled, (draft, { payload }) => {
      if (draft.loading) {
        const {
          details: { name },
        } = payload;
        systemLocksAdapter.removeOne(draft, name);
        draft.loading = false;
        draft.error = null;
      }
    });
    builder.addCase(deleteSystemLockByName.rejected, (draft, action) => {
      if (draft.loading) {
        draft.loading = false;
        draft.error = action.payload;
      }
    });
  },
});
const versionSelector = ({ admin }) => admin.versions;
const appConfigSelector = ({ admin }) => admin.appConfig;
const systemLogsSelector = ({ logs }) => logs.systemLogs;
const systemLockLoadingSelector = ({ admin }) => admin.systemlocks.loading;

const systemExecutionsLockedSelector = ({
  admin: {
    systemlocks: { entities },
  },
}) => !!entities.executions;

export {
  fetchSystemLocksByName,
  createSystemLockByName,
  deleteSystemLockByName,
  versionSelector,
  appConfigSelector,
  systemLogsSelector,
  systemLockLoadingSelector,
  systemExecutionsLockedSelector,
};

export default combineReducers({
  appConfig,
  versions,
  systemlocks: systemLockSlice.reducer,
});
