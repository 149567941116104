import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  isPending,
  isAsyncThunkAction,
} from '@reduxjs/toolkit';
import type {
  EntityState,
  PayloadAction,
  CaseReducer,
  AsyncThunk,
} from '@reduxjs/toolkit';

import { uniq, omit, filter } from 'lodash';
import type { NormalizedSchema } from 'normalizr';
import { combineReducers } from 'redux';
import type { Action, Reducer } from 'redux';

import { callApi } from 'common/util/createFetch';
import { SET_CURRENT_PROJECT } from 'common/constants';
import * as actionTypes from 'common/constants/connections';
import type { Connection, ConnectionType } from 'common/types/connection';

type ConnState = {
  ids: string[],
  detail: any,
  entities: { [uuid: string]: Connection },
  isFetching: boolean,
  isSubmitting: boolean,
  isFetchingDetail: boolean,
};

type TypeState = {
  ids: string[],
  entities: { [key: string]: ConnectionType },
  isFetching: boolean,
};

type VersionState = EntityState<Connection> & {
  isFetching: boolean,
  isDeleting: boolean,
  loading: string,
  error: null | any,
  currentRequestId?: string,
  transactionId: number | null,
};

type DetailState = { [key: string]: TypeState };

type ConnectionState = {
  detail: DetailState,
  versions: VersionState,
  connectionTypes: TypeState,
  connections: ConnState,
};

interface ConnectionAction extends Action<string> {
  error: any;
  timestamp: number;
  payload:
    | (NormalizedSchema<Connection, string | string[]> | { ids: string[] })
    | { uuid: string };
}

// Initial State
const initialConnectionState = {
  ids: [],
  detail: {},
  entities: {},
  isFetching: false,
  isSubmitting: false,
  isFetchingDetail: false,
};

const connectionsReducer: Reducer<ConnState, ConnectionAction> = (
  state = initialConnectionState,
  action,
) => {
  switch (action.type) {
    case SET_CURRENT_PROJECT:
      return initialConnectionState;

    case actionTypes.LOAD_CONNECTION_REQUEST:
    case actionTypes.LOAD_CONNECTIONS_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.LOAD_CONNECTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        entities: {
          ...state.entities,
          ...action.payload.entities.connections,
        },
        ids: uniq([...state.ids, ...action.payload.ids]),
      };

    case actionTypes.LOAD_CONNECTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ids: uniq([...state.ids, action.payload.uuid]),
        entities: {
          ...state.entities,
          ...action.payload.entities.connections,
        },
      };

    case actionTypes.UPDATE_CONNECTION_SUCCESS:
    case actionTypes.CREATE_CONNECTION_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isFetching: false,
      };

    case actionTypes.CREATE_CONNECTION_REQUEST:
    case actionTypes.UPDATE_CONNECTION_REQUEST:
    case actionTypes.DELETE_CONNECTION_REQUEST:
      return { ...state, isSubmitting: true };

    case actionTypes.CREATE_CONNECTION_FAILURE:
    case actionTypes.UPDATE_CONNECTION_FAILURE:
    case actionTypes.DELETE_CONNECTION_FAILURE:
      return {
        ...state,
        error: action.error,
        timestamp: action.timestamp,
        isSubmitting: false,
      };

    case actionTypes.DELETE_CONNECTION_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        entities: omit(state.entities, [action.payload.uuid]),
        ids: filter(state.ids, (uuid) => uuid !== action.payload.uuid),
      };

    case actionTypes.CONNECTION_DETAIL_REQUEST:
      return { ...state, isFetchingDetail: true };

    case actionTypes.CONNECTION_DETAIL_FAILURE:
      return {
        ...state,
        error: action.error,
        timestamp: action.timestamp,
        isFetchingDetail: false,
      };

    case actionTypes.CONNECTION_DETAIL_SUCCESS:
      return { ...state, isFetchingDetail: false, detail: action.payload };

    case actionTypes.CLEAR_CONNECTION_DETAIL:
      return { ...state, detail: {} };

    default:
      return state;
  }
};

const initialTypeState = {
  ids: [],
  entities: {},
  isFetching: false,
};

const connectionTypesReducer: Reducer<TypeState> = (
  state = initialTypeState,
  action,
) => {
  switch (action.type) {
    case actionTypes.LOAD_CONNECTION_TYPE_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.LOAD_CONNECTION_TYPE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        connectionTypes: action.connectionTypes,
      };

    case actionTypes.LOAD_CONNECTION_TYPES_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.LOAD_CONNECTION_TYPES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        timestamp: action.timestamp,
      };

    case actionTypes.LOAD_CONNECTION_TYPES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ids: uniq([...state.ids, ...action.payload.ids]),
        entities: {
          ...state.entities,
          ...action.payload.entities.connectionTypes,
        },
      };

    case actionTypes.SELECT_CONNECTION_TYPE:
      return {
        ...state,
        detail: state.entities[action.payload.uuid],
        isFetching: false,
      };

    default:
      return state;
  }
};

const detailReducer: Reducer<DetailState> = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SELECT_CONNECTION_TYPE:
      return {
        ...state,
        [action.payload.uuid]: connectionTypesReducer(
          state[action.payload.uuid],
          action,
        ),
      };

    default:
      return state;
  }
};

const versionsAdapter = createEntityAdapter<Connection>({
  selectId: (model) => model.transactionId,
  sortComparer: (a, b) => b.transactionId - a.transactionId,
});

const initialVersionState: VersionState = versionsAdapter.getInitialState({
  loading: 'idle',
  transactionId: null,
  isFetching: false,
  isDeleting: false,
  currentRequestId: undefined,
  error: null,
});

const fetchVersionsByUuid: AsyncThunk<
  Connection[],
  { uuid: string, projectUuid: string },
> = createAsyncThunk(
  'versions/fetchByUuid',
  async (arg, api) => {
    const { uuid, projectUuid } = arg;
    const { signal, rejectWithValue } = api;
    try {
      const results = await callApi(
        `/api/v2/projects/${projectUuid}/connections/${uuid}/versions`,
        'GET',
        undefined,
        { signal },
      );

      if (results.error)
        return rejectWithValue(results.errorData || results.error);

      return results;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
  {},
);

const deleteVersionByUuid: AsyncThunk<
  any,
  { uuid: string, version: number, projectUuid?: string },
> = createAsyncThunk(
  'versions/deleteVersionByUuid',
  async ({ uuid, version, projectUuid }, { signal, rejectWithValue }) => {
    try {
      const results = await callApi(
        `/api/v2/projects/${projectUuid}/connections/${uuid}/versions/${version}`,
        'DELETE',
        undefined,
        { signal },
      );

      if (results.error) {
        return rejectWithValue(results.errorData || results.error);
      }

      return results;
    } catch (err) {
      return rejectWithValue(err.data || err);
    }
  },
  {},
);

const isFetching = isPending(fetchVersionsByUuid);
const isDeleting = isPending(deleteVersionByUuid);
const connectionVersionSlice = createSlice<
  VersionState,
  {
    versionAdded: versionsAdapter.addOne,
    versionsAdded: versionsAdapter.addMany,
    versionsClear: versionsAdapter.removeAll,
    versionDeleted: versionsAdapter.removeOne,
    setVersion: CaseReducer<VersionState, PayloadAction<number>>,
  },
  'versions',
>({
  name: 'versions',
  initialState: initialVersionState,

  reducers: {
    versionAdded: versionsAdapter.addOne,
    versionsAdded: versionsAdapter.addMany,
    versionsClear: versionsAdapter.removeAll,
    versionDeleted: versionsAdapter.removeOne,
    setVersion: (draft, action) => {
      draft.transactionId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVersionsByUuid.pending, (draft, action) => {
      if (draft.loading === 'idle') {
        draft.loading = 'pending';
        draft.error = null;
        draft.currentRequestId = action.meta.requestId;
      }
    });

    builder.addCase(fetchVersionsByUuid.fulfilled, (draft, action) => {
      const { requestId } = action.meta;
      if (draft.loading === 'pending' && draft.currentRequestId === requestId) {
        versionsAdapter.addMany(draft, action.payload);
        draft.loading = 'idle';
        draft.error = null;
        draft.currentRequestId = undefined;
      }
    });

    builder.addCase(fetchVersionsByUuid.rejected, (draft, action) => {
      const { requestId } = action.meta;
      if (draft.loading === 'pending' && draft.currentRequestId === requestId) {
        draft.loading = 'idle';
        draft.error = { ...action.error, ...action.payload };
        draft.currentRequestId = undefined;
      }
    });

    builder.addCase(deleteVersionByUuid.pending, (draft, action) => {
      const { requestId } = action.meta;
      if (draft.loading === 'idle') {
        draft.loading = 'pending';
        draft.error = null;
        draft.currentRequestId = requestId;
      }
    });

    builder.addCase(deleteVersionByUuid.fulfilled, (draft, action) => {
      const { requestId } = action.meta;
      if (draft.loading === 'pending' && draft.currentRequestId === requestId) {
        // versionsAdapter.removeOne(state, action.payload);
        draft.loading = 'idle';
        draft.error = null;
        draft.currentRequestId = undefined;
      }
    });

    builder.addCase(deleteVersionByUuid.rejected, (draft, action) => {
      const { requestId } = action.meta;
      if (draft.loading === 'pending' && draft.currentRequestId === requestId) {
        draft.loading = 'idle';
        draft.error = action.error;
        draft.currentRequestId = undefined;
      }
    });

    builder.addMatcher(isAsyncThunkAction, (draft, action) => {
      draft.isFetching = isFetching(action);
      draft.isDeleting = isDeleting(action);
    });

    // builder.addMatcher(
    //   isDeletingAction(fetchVersionsByUuid),
    //   (state, action) => {
    //     state.isDeleting = isDeleting(action);
    //   },
    // );
  },
});

export type { ConnectionState, VersionState, ConnState, TypeState };
export {
  versionsAdapter,
  deleteVersionByUuid,
  fetchVersionsByUuid,
  connectionVersionSlice,
};
connectionVersionSlice.actions.versionsClear();

// Export Reducer
export default combineReducers({
  detail: detailReducer,
  versions: connectionVersionSlice.reducer,
  connections: connectionsReducer,
  connectionTypes: connectionTypesReducer,
});
