import {
  LOGOUT_SUCCESS,
  SET_LOCAL_DB,
  SET_LOCAL_ES,
  SET_CONFIG_ITEM,
  REQUEST_USER_ORGANIZATIONS,
  REQUEST_USER_ORGANIZATIONS_SUCCESS,
  REQUEST_USER_ORGANIZATIONS_FAILURE,
  SETUP_LOGIN_FAILURE,
  SETUP_LOGIN_SUCCESS,
  REQUEST_SETUP_LOGIN,
} from '../constants';

const initialSetupState = {
  error: null,
  wizard: {
    username: null,
    organization: null,
  },
  userOrgs: [],
  fetchingUserOrgs: false,
  authentication: {
    isFetching: false,
    error: null,
    authenticated: false,
  },
};

const setup = (state = initialSetupState, action) => {
  switch (action.type) {
    case SETUP_LOGIN_FAILURE:
      return {
        ...state,
        authentication: {
          isFetching: false,
          authenticated: false,
          error: action.error,
        },
      };
    case SETUP_LOGIN_SUCCESS:
      return {
        ...state,
        authentication: {
          isFetching: false,
          error: null,
          authenticated: true,
        },
      };
    case REQUEST_SETUP_LOGIN:
      return {
        ...state,
        authentication: {
          isFetching: true,
          error: null,
          authenticated: false,
        },
      };
    case SET_CONFIG_ITEM:
      return {
        ...state,
        wizard: {
          ...state.wizard,
          ...action.payload,
        },
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialSetupState,
      };

    case REQUEST_USER_ORGANIZATIONS:
      return {
        ...state,
        fetchingUserOrgs: true,
      };

    case REQUEST_USER_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        authenticated: true,
        fetchingUserOrgs: false,
        username: action.payload.username,
        userOrgs: action.payload.organizations,
        wizard: {
          ...state.wizard,
          username: action.payload.username,
        },
      };

    case REQUEST_USER_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        authenticated: false,
        fetchingUserOrgs: false,
        error: action.error,
        timestamp: action.error,
      };

    case SET_LOCAL_ES:
      return {
        ...state,
        wizard: {
          ...state.wizard,
          esConfig: initialSetupState.esConfig,
        },
      };

    case SET_LOCAL_DB:
      return {
        ...state,
        wizard: {
          ...state.wizard,
          dbConfig: initialSetupState.dbConfig,
        },
      };

    default:
      return state;
  }
};

export default setup;
