import { keyBy, omit, sortedUniq, uniq } from 'lodash';
import { ofType } from 'redux-observable';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';

import type { Epic } from '../store/types';
import type { EngineType, TaskType } from '../types/engine';
import type { SharedObject } from '../types/sharedObjects';
import {
  ACTION_CHANGE,
  CLEAR_ACTION_PARAMETERS,
  CREATE_TASK_FAILURE,
  CREATE_TASK_REQUEST,
  CREATE_TASK_SUCCESS,
  DELETE_TASK_FAILURE,
  DELETE_TASK_REQUEST,
  DELETE_TASK_SUCCESS,
  LOAD_TASKS_FAILURE,
  LOAD_TASKS_REQUEST,
  LOAD_TASKS_SUCCESS,
  LOAD_TASK_REQUEST,
  LOAD_TASK_SUCCESS,
  RESET_TASKS_PAGE,
  SET_ACTION_PARAMETERS,
  SET_CURRENT_PROJECT,
  TASK_CHANNEL_CHANGE,
  TASK_DETAIL_FAILURE,
  TASK_DETAIL_REQUEST,
  TASK_DETAIL_SUCCESS,
  TASK_VERSIONS_CANCELLED,
  TASK_VERSIONS_REJECTED,
  TASK_VERSIONS_REQUEST,
  TASK_VERSIONS_SUCCESS,
  UPDATE_TASK_FAILURE,
  UPDATE_TASK_REQUEST,
  UPDATE_TASK_SUCCESS,
} from '../constants';
import { createRxFetch } from '../util/createFetch';

export type TaskState = {
  error?: any,
  ids: Array<string>,
  isFetching: boolean,
  isSubmitting: boolean,
  entities: {
    tasks: { [key: string]: TaskType },
    engines: { [key: string]: EngineType },
  },
  versions: {
    ids: number[],
    entities: { [key: string | number]: TaskType },
    error: { message: string, status: string, code: number } | null,
    isFetching: boolean,
  },
  detail: {
    actions: { [key: string]: any },
    actionName: string,
    actionParameters: any,
    parameters: any,
    fetchedSharedObjects: { [key: string]: SharedObject },
  },
  filterSettings: {
    page: number,
    alias: string,
    perPage: number,
    status: string,
  },
  metaData: {
    rows: number,
    total: number,
    perPage: number,
  },
  pages: { [key: string]: { ids: Array<string>, isFetching: boolean } },
};

// Initial State
const initialState: TaskState = {
  ids: [],
  isFetching: false,
  isSubmitting: false,
  entities: { tasks: {}, engines: {} },
  versions: {
    ids: [],
    entities: {},
    error: null,
    isFetching: false,
  },
  detail: {
    actions: {},
    actionName: '',
    actionParameters: {},
    parameters: {},
    fetchedSharedObjects: {},
  },
  filterSettings: {
    page: 1,
    alias: '',
    perPage: 10,
    status: 'Any',
  },
  metaData: {
    rows: 0,
    total: 0,
    perPage: 10,
  },
  pages: {
    1: {
      ids: [],
      isFetching: false,
    },
  },
};

const tasks = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PROJECT:
      return initialState;

    case 'SET_DETAIL_CHANNEL':
      return {
        ...state,
        detail: { ...state.detail, ...action.payload },
      };

    case TASK_DETAIL_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...action.payload,
        },
      };

    case ACTION_CHANGE:
      return {
        ...state,
        detail: {
          ...state.detail,
          actionName: action.payload,
          actionParameters: state.detail.actions[action.payload] || {},
        },
      };
    case TASK_CHANNEL_CHANGE:
      return {
        ...state,
        detail: {
          ...state.detail,
          // channel: action.payload,
          options: {
            ...state.detail.options,
            channel: action.payload,
          },
        },
      };
    case SET_ACTION_PARAMETERS:
      return {
        ...state,
        detail: {
          ...state.detail,
          parameters: {
            ...state.detail.parameters,
            [action.payload.actionName]: action.payload.parameters,
          },
        },
      };

    case CLEAR_ACTION_PARAMETERS:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...initialState.detail,
        },
      };

    case 'LOAD_SHARED_OBJECTS_BY_TYPE':
      return {
        ...state,
        detail: {
          ...state.detail,
          fetchedSharedObjects: {
            ...state.detail.fetchedSharedObjects,
            [action.payload]: true,
          },
        },
      };

    case TASK_DETAIL_REQUEST:
    case LOAD_TASK_REQUEST:
      return { ...state, isFetching: true, error: undefined };

    case CREATE_TASK_FAILURE:
    case UPDATE_TASK_FAILURE:
    case DELETE_TASK_FAILURE:
    case TASK_DETAIL_FAILURE:
      return {
        ...state,
        error: action.error,
        timestamp: action.timestamp,
        isSubmitting: false,
        isFetching: false,
      };

    case CREATE_TASK_REQUEST:
    case UPDATE_TASK_REQUEST:
    case DELETE_TASK_REQUEST:
      return { ...state, isSubmitting: true, error: undefined };

    case CREATE_TASK_SUCCESS:
    case UPDATE_TASK_SUCCESS:
    case LOAD_TASK_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          tasks: {
            ...state.entities.tasks,
            ...action.payload.entities.tasks,
          },
          engines: {
            ...state.entities.engines,
            ...(action.payload.entities.engines
              ? action.payload.entities.engines
              : {}),
          },
        },
        ids: uniq([...state.ids, action.payload.result]),
        isFetching: false,
        isSubmitting: false,
      };

    case DELETE_TASK_SUCCESS:
      return {
        ...state,
        entities: omit(state.entities, [action.uuid]),
        ids: state.ids.filter((uuid) => uuid !== action.uuid),
        isSubmitting: false,
      };

    case LOAD_TASKS_REQUEST:
      return {
        ...state,
        isFetching: true,
        filterSettings: {
          ...state.filterSettings,
          ...action.filterSettings,
        },
        pages: {
          ...state.pages,
          [action.filterSettings.page]: {
            ids: state.pages[action.filterSettings.page]
              ? state.pages[action.filterSettings.page].ids
              : [],
            isFetching: true,
          },
        },
      };

    case LOAD_TASKS_FAILURE:
      return {
        ...state,
        ...state,
        filterSettings: {
          ...state.filterSettings,
        },
        pages: {
          ...state.pages,
          [action.payload.page]: {
            ids: state.pages[action.payload.page]
              ? state.pages[action.payload.page].ids
              : [],
            isFetching: false,
          },
        },
        isFetching: false,
        error: action.error,
        timestamp: action.timestamp,
      };

    case LOAD_TASKS_SUCCESS:
      return {
        ...state,
        entities: {
          engines: { ...state.entities.engines, ...action.entities.engines },
          tasks: { ...state.entities.tasks, ...action.entities.tasks },
        },
        ids: [
          ...state.ids,
          ...action.result.filter((ix) => !state.ids.includes(ix)),
        ],
        isFetching: false,
        metaData: action.metaData,
        filterSettings: {
          ...state.filterSettings,
          ...action.filterSettings,
        },
        // pages: {
        //   ...state.pages,
        //   [action.filterSettings.page]: {
        //     ids: union(
        //       state.pages[action.filterSettings.page].ids,
        //       action.result,
        //     ),
        //     isFetching: false,
        //   },
        // },
      };

    case RESET_TASKS_PAGE:
      return {
        ...state,
        filterSettings: {
          ...state.filterSettings,
          page: 1,
        },
        pages: {
          1: {
            ids: [],
            isFetching: false,
          },
        },
      };

    case TASK_VERSIONS_REQUEST:
      return {
        ...state,
        versions: {
          ...state.versions,
          error: null,
          isFetching: true,
        },
      };

    case TASK_VERSIONS_REJECTED:
      return {
        ...state,
        versions: {
          ...state.versions,
          isFetching: false,
          error: action.payload,
        },
      };

    case TASK_VERSIONS_CANCELLED:
      return {
        ...state,
        versions: {
          ...state.versions,
          isFetching: false,
        },
      };

    case TASK_VERSIONS_SUCCESS:
      return {
        ...state,
        versions: {
          ...state.versions,
          isFetching: false,
          entities: {
            ...state.entities,
            [action.payload.uuid]: action.payload.entities,
          },
          ids: sortedUniq(
            state.ids.concat(action.payload.ids).sort((a, b) => b - a),
          ),
        },
      };

    default:
      return state;
  }
};

// TASK_VERSIONS
// --------------------------------------------------------
const taskVersionEpic: Epic = (action$, state$, { currentProject }) => {
  return action$.pipe(
    ofType(TASK_VERSIONS_REQUEST),
    switchMap((action) =>
      createRxFetch({
        url: `/api/v2/projects/${
          action.payload.projectUuid || currentProject(state$.value)
        }/tasks/${action.payload.uuid}/versions`,
      })
        .pipe(
          map(({ data }) => data.map((o, ix) => ({ ...o, version: ix + 1 }))),
        )
        .pipe(
          map((data) => {
            const ids = data.map(({ transactionId }) => transactionId);
            const entities = keyBy(data, 'transactionId');
            return {
              type: TASK_VERSIONS_SUCCESS,
              payload: {
                ids,
                entities,
                uuid: action.payload.uuid,
              },
            };
          }),
          catchError((error) =>
            of({
              type: TASK_VERSIONS_REJECTED,
              payload: error,
              error: true,
            }),
          ),
          takeUntil(action$.pipe(ofType(TASK_VERSIONS_CANCELLED))),
        ),
    ),
  );
};

export { taskVersionEpic };
export default tasks;
