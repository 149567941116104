import {
  SET_RUNTIME_VARIABLE,
  CHECK_INITIALIZED_REQUEST,
  CHECK_INITIALIZED_FAILURE,
  CHECK_INITIALIZED_SUCCESS,
  API_STATUS_RECEIVED,
  API_STATUS_ERROR,
} from '../constants';

export type RuntimeState = {
  initialized: boolean,
  nomitallUrl: string,
  error?: { status: string, code: number } | string | null,
  isFetching: boolean,
};

const initialState: RuntimeState = {
  initialized: true,
  hasFetched: false,
  nomitallUrl: null,
  error: null,
  isFetching: false,
};

export default function runtime(state = initialState, action) {
  switch (action.type) {
    case API_STATUS_RECEIVED:
      return {
        ...state,
        error: null,
        hasFetched: action.hasFetched,
        initialized: action.payload.initialized,
        nomitallUrl: action.payload.nomitallUrl,
      };

    case API_STATUS_ERROR:
      return {
        ...state,
        error: action.error,
        hasFetched: action.hasFetched,
        initialized: action.error.initialized,
        nomitallUrl: action.error.nomitall_url,
        timestamp: action.timestamp || Date.now(),
      };

    case SET_RUNTIME_VARIABLE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };

    case CHECK_INITIALIZED_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case CHECK_INITIALIZED_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        timestamp: action.timestamp,
      };

    case CHECK_INITIALIZED_SUCCESS:
      return {
        ...state,
        isFetching: false,
        initialized: action.initialized,
      };

    default:
      return state;
  }
}
