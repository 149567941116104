import { createSelector } from 'reselect';
import { getSearchSelectors } from 'redux-search';

import { sortBy } from 'lodash';
import type { Project } from 'common/types/project';
import type { Selector, ParametricSelector } from 'common/store/types';

/**
 * Get current project filter
 *
 * @param state {RootState}
 * @return {string}
 */
const currentProjectFilter: Selector<string> = (state) => {
  return state.projects.currentProject;
};

/**
 * Get current project
 *
 * @param state {RootState}
 * @return {Project}
 */
const getCurrentProject: Selector<Project> = (state) => {
  return state.projects.entities[currentProjectFilter(state)];
};

/**
 * Get all projects
 *
 * @param state {RootState}
 * @return {Project[]}
 */
const getProjects: Selector<Project[]> = (state) => {
  return state.projects.ids.map((uuid) => state.projects.entities[uuid]);
};

/**
 * Get project by uuid
 *
 * @param state {RootState}
 * @param uuid {string}
 * @return {Project}
 */
const getProject: ParametricSelector<string, Project> = (state, uuid) => {
  return state.projects.entities[uuid];
};

const getIsFetching: Selector<boolean> = (state) => {
  return state.projects.isFetching;
};

const getError: Selector<any> = (state) => {
  return state.projects.error;
};

/**
 * Get entities for projects
 *
 * @param state: {RootState}
 * @return {Project}
 */
const getProjectEntities: Selector<Project> = (state) => {
  return state.projects.entities;
};

const getDefaultProject: Selector<Project> = (state) => {
  return getProject(state, '00000-00000');
};

const { text, result } = getSearchSelectors({
  resourceName: 'projects',
  resourceSelector: (resourceName, state) => state[resourceName].entities,
});

const selectors = createSelector(
  [result, text, getDefaultProject, getProjectEntities, getIsFetching],
  (projectUuids, searchText, defaultProject, projects, isFetching) => {
    return {
      searchText,
      isFetching,
      defaultProject,
      projects: sortBy(
        projectUuids.map((uuid) => projects[uuid]),
        ({ uuid, alias }) => {
          if (uuid === '00000-00000') {
            return -1;
          }
          return alias.toLowerCase();
        },
      ),
    };
  },
);

export {
  selectors,
  getError,
  getProject,
  getProjects,
  getIsFetching,
  getCurrentProject,
  getProjectEntities,
  currentProjectFilter,
};
