import { uniq, omit, orderBy } from 'lodash';
import type { Sequence } from '../types/sequence';
import {
  LOAD_SEQUENCES_REQUEST,
  LOAD_SEQUENCES_FAILURE,
  LOAD_SEQUENCES_SUCCESS,
  LOAD_SEQUENCE_REQUEST,
  LOAD_SEQUENCE_FAILURE,
  LOAD_SEQUENCE_RESET,
  LOAD_SEQUENCE_SUCCESS,
  RUN_SEQUENCE_REQUEST,
  RUN_SEQUENCE_FAILURE,
  RUN_SEQUENCE_SUCCESS,
  CREATE_SEQUENCE_REQUEST,
  CREATE_SEQUENCE_FAILURE,
  CREATE_SEQUENCE_SUCCESS,
  UPDATE_SEQUENCE_REQUEST,
  UPDATE_SEQUENCE_FAILURE,
  UPDATE_SEQUENCE_SUCCESS,
  LOAD_SEQUENCE_EXECUTIONS_SUCCESS,
  LOAD_SEQUENCE_EXECUTIONS_REQUEST,
  LOAD_SEQUENCE_TASK_EXECUTIONS_FAILURE,
  LOAD_SEQUENCE_TASK_EXECUTIONS_SUCCESS,
  LOAD_SEQUENCE_TASK_EXECUTIONS_REQUEST,
  PATCH_SEQUENCE_REQUEST,
  PATCH_SEQUENCE_SUCCESS,
  PATCH_SEQUENCE_FAILURE,
  DELETE_SEQUENCE_SUCCESS,
  KILL_SEQUENCE_SUCCESS,
  KILL_SEQUENCE_REQUEST,
  KILL_SEQUENCE_FAILURE,
} from '../constants';

export type ExecuteState = {
  error: string | any,
  sequenceUuid: string,
  isRequesting: boolean,
  isExecuting: boolean,
};

export type ErrorState =
  | any
  | string
  | {
      code: number,
      message: string,
      status: string,
    }
  | null;

export interface SequencesState {
  sequence: Sequence | {};
  isFetchingSequence: boolean;
  isFetchingSequenceExecutions: boolean;
  isFetchingSequenceTaskExecution: any;
  killRequests: {};
  sequenceExecutions: any[];
  sequenceExecutionFilters: any;
  sequenceTaskExecutions: any;
  sequenceTaskExecutionsFilters: any;
  ids: string[];
  entities: { [key: string]: Sequence };
  filters: any;
  execute: ExecuteState;
  error: ErrorState;
  isCreating: boolean;
  isUpdating: boolean;
}

// Initial State
const initialState: SequencesState = {
  sequence: {},
  isFetchingSequence: false,
  isFetchingSequenceExecutions: false,
  isFetchingSequenceTaskExecution: {},
  sequenceExecutions: [],
  killRequests: {},
  sequenceExecutionFilters: {},
  sequenceTaskExecutions: {},
  sequenceTaskExecutionsFilters: {},
  ids: [],
  entities: {},
  filters: {},
  error: null,
  execute: {
    error: '',
    sequenceUuid: '',
    isRequesting: false,
    isExecuting: false,
  },
  isCreating: false,
  isUpdating: false,
};

const reducer = (state: SequencesState = initialState, action) => {
  switch (action.type) {
    case RUN_SEQUENCE_REQUEST:
      return {
        ...state,
        execute: {
          ...state.execute,
          sequenceUuid: action.payload,
          isRequesting: true,
        },
      };

    case RUN_SEQUENCE_FAILURE:
      return {
        ...state,
        execute: {
          ...state.execute,
          error: action.error.message,
          isRequesting: false,
          timestamp: action.timestamp,
          isExecuting: false,
        },
      };

    case RUN_SEQUENCE_SUCCESS:
      return {
        ...state,
        execute: {
          ...state.execute,
          isRequesting: false,
          isExecuting: true,
        },
        ids: uniq([...state.ids, action.payload.sequenceUuid]),
        entities: {
          ...state.entities,
          [action.payload.sequenceUuid]: {
            ...state.entities[action.payload.sequenceUuid],
            ...action.payload.sequence,
          },
        },
      };

    case LOAD_SEQUENCE_EXECUTIONS_REQUEST:
      return {
        ...state,
        sequenceExecutionFilters: {
          ...state.filters,
          ...action.payload.filters,
        },
        isFetchingSequenceExecutions: true,
      };

    case LOAD_SEQUENCE_EXECUTIONS_SUCCESS:
      return {
        ...state,
        sequenceExecutions: orderBy(
          action.payload.sequenceExecutions,
          ({ created }) => created,
          ['desc'],
        ),
        sequenceExecutionFilters: {
          ...state.filters,
          ...action.payload.filters,
        },
        isFetchingSequenceExecutions: false,
      };

    case KILL_SEQUENCE_REQUEST:
      return {
        ...state,
        killRequests: {
          ...state.killRequests,
          [action.payload.executionId]: true,
        },
      };
    case KILL_SEQUENCE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        killRequests: {
          ...state.killRequests,
          [action.payload.executionId]: false,
        },
      };
    case KILL_SEQUENCE_SUCCESS:
      return {
        ...state,
        killRequests: {
          ...state.killRequests,
          [action.payload.executionId]: false,
        },
        sequenceExecutions: state.sequenceExecutions.map((exe) => {
          if (exe.uuid === action.payload.killedExecution.uuid) {
            return action.payload.killedExecution;
          }
          return exe;
        }),
      };
    case LOAD_SEQUENCE_TASK_EXECUTIONS_REQUEST:
      return {
        ...state,
        isFetchingSequenceTaskExecution: {
          ...state.isFetchingSequenceTaskExecution,
          [action.payload.sequenceExecutionUuid]: true,
        },
        sequenceTaskExecutionFilters: {
          ...state.filters,
          ...action.payload.filters,
        },
      };

    case LOAD_SEQUENCE_TASK_EXECUTIONS_FAILURE:
      return {
        ...state,
        isFetchingSequenceTaskExecution: {
          ...state.isFetchingSequenceTaskExecution,
          [action.payload.sequenceExecutionUuid]: false,
        },
        sequenceTaskExecutionFilters: {
          ...state.filters,
          ...action.payload.filters,
        },
      };

    case LOAD_SEQUENCE_TASK_EXECUTIONS_SUCCESS:
      return {
        ...state,
        isFetchingSequenceTaskExecution: {
          ...state.isFetchingSequenceTaskExecution,
          [action.payload.sequenceExecutionUuid]: false,
        },
        sequenceTaskExecutions: {
          ...state.sequenceTaskExecutions,
          [action.payload.sequenceExecutionUuid]:
            action.payload.sequenceTaskExecutions,
        },
        sequenceTaskExecutionFilters: {
          ...state.filters,
          ...action.payload.filters,
        },
      };
    case LOAD_SEQUENCES_REQUEST:
      return {
        ...state,
        error: null,
        isFetchingSequence: true,
        sequenceFilters: {
          ...state.filters,
          ...action.payload.filters,
        },
      };

    case LOAD_SEQUENCES_FAILURE:
      return {
        ...state,
        isFetchingSequence: false,
        error: action.error,
        timestamp: action.timestamp,
      };

    case LOAD_SEQUENCES_SUCCESS:
      return {
        ...state,
        error: null,
        ids: uniq([...action.payload.ids, ...state.ids]),
        entities: { ...state.entities, ...action.payload.entities },
        isFetchingSequence: false,
        sequenceFilters: {
          ...state.filters,
          ...action.payload.filters,
        },
      };

    case LOAD_SEQUENCE_RESET:
      return { ...state, sequence: initialState.sequence };

    case LOAD_SEQUENCE_REQUEST:
      return { ...state, sequence: {}, error: null, isFetchingSequence: true };

    case LOAD_SEQUENCE_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetchingSequence: false,
        timestamp: action.timestamp,
      };

    case UPDATE_SEQUENCE_SUCCESS:
    case CREATE_SEQUENCE_SUCCESS:
    case LOAD_SEQUENCE_SUCCESS:
      return {
        ...state,
        sequence: action.payload.sequence,
        ids: uniq([action.payload.uuid, ...state.ids]),
        entities: {
          ...state.entities,
          [action.payload.uuid]: {
            ...(state.entities[action.payload.uuid] || {}),
            ...action.payload.sequence,
          },
        },
        isCreating: false,
        isUpdating: false,
        isFetchingSequence: false,
      };

    case CREATE_SEQUENCE_REQUEST:
      return { ...state, isCreating: true };

    case CREATE_SEQUENCE_FAILURE:
      return {
        ...state,
        isCreating: false,
        error: action.error,
        timestamp: action.timestamp,
      };

    case UPDATE_SEQUENCE_REQUEST:
      return { ...state, isUpdating: true };

    case UPDATE_SEQUENCE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.error,
        timestamp: action.timestamp,
      };

    case PATCH_SEQUENCE_REQUEST:
      return { ...state, isUpdating: true };

    case PATCH_SEQUENCE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.error,
        timestamp: action.timestamp,
      };
    case PATCH_SEQUENCE_SUCCESS:
      return {
        ...state,
        sequence: action.payload.sequence,
        isUpdating: false,
        // ids: uniq(action.payload.uuid, ...state.ids),
        entities: {
          ...state.entities,
          [action.payload.uuid]: {
            ...(state.entities[action.payload.uuid] || {}),
            ...action.payload.sequence,
          },
        },
      };
    case DELETE_SEQUENCE_SUCCESS:
      return {
        ...state,
        entities: omit(state.entities, [action.payload]),
        ids: state.ids.filter((uuid) => uuid !== action.payload),
      };

    default:
      return state;
  }
};

// Export Reducer
export default reducer;
