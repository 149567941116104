import type { Reducer } from 'redux';
import type { Action } from 'common/store/types';

import {
  EDIT_PASSWORD_CLEAR,
  EDIT_PASSWORD_FAILURE,
  EDIT_PASSWORD_REQUEST,
  EDIT_PASSWORD_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
} from '../constants/index';

type AuthState = {
  forgotMsg: string,
  error: string | any | null,
  timestamp: number | string,
  loading: boolean,
  authenticated: boolean,
  isFetching: boolean,
};

const initialLoginState = {
  error: '',
  timestamp: '',
  isFetching: false,
  authenticated: false,
};

const reducer: Reducer<AuthState, Action> = (
  state = initialLoginState,
  action,
) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, authenticated: true, error: null, isFetching: false };
    case LOGIN_REQUEST:
      return { ...state, isFetching: true, authenticated: false };
    case LOGOUT_SUCCESS:
      return { ...state, isFetching: false, authenticated: false };
    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        timestamp: action.timestamp,
        authenticated: false,
      };
    default:
      return state;
  }
};

const initialChangePasswordState = {
  error: '',
  timestamp: '',
  isEditing: false,
};

export const changePassword = (state = initialChangePasswordState, action) => {
  switch (action.type) {
    case EDIT_PASSWORD_REQUEST:
      return {
        ...state,
        isEditing: true,
      };
    case EDIT_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error,
        timestamp: action.timestamp,
        isEditing: false,
      };
    case EDIT_PASSWORD_SUCCESS:
      return {
        isEditing: false,
      };
    case EDIT_PASSWORD_CLEAR:
      return {
        ...state,
        ...initialChangePasswordState,
      };
    default:
      return state;
  }
};

export type { AuthState };
export default reducer;
