import React from 'react';
import ReactDOM from 'react-dom';
import { loadableReady } from '@loadable/component';
import { Router } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';

import configureStore from './common/store/configureStore';
import AppContainer from './App';
import AppRouter from './router';

let currentProject = localStorage.getItem('project');
if (typeof currentProject === 'string' && currentProject === 'undefined') {
  localStorage.removeItem('project');
  currentProject = undefined;
}

const { history, store } = configureStore(
  {
    ...window.__mns.state,
    projects: {
      ...window.__mns.state.projects,
      currentProject,
    },
  },
  window.location,
);

if (__DEV__) {
  history.listen((location, action) => {
    console.info(action, location.pathname, location.state);
  });
}

const root = document.getElementById('root');
loadableReady(() => {
  ReactDOM.hydrate(
    <ReduxProvider store={store}>
      <AppContainer>
        <>
          <Helmet>
            {!__DEV__ ? (
              <script
                defer
                src="https://desk.zoho.com/portal/api/web/inapp/450342000000254001?orgId=699633101"
                type="text/javascript"
              />
            ) : null}
          </Helmet>
          <Router history={history}>
            <AppRouter />
          </Router>
        </>
      </AppContainer>
    </ReduxProvider>,
    root,
  );
});

if (module.hot) {
  module.hot.accept();
}
