import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import { reducer as searchReducer } from 'redux-search';

import admin from './admin';
import auth, { changePassword } from './auth';
import type { AuthState } from './auth';
import connections, { connectionVersionSlice } from './connections';
import type { ConnectionState } from './connections';
import dashboards from './dashboards';
import type { DashboardsState } from './dashboards';
import drawer from './drawer';
import type { DrawerState } from './drawer';
import engines, {
  fetchParameterCategoriesEpic,
  fetchEngineCategoriesEpic,
} from './engines';
import type { EngineState } from './engines';
import executions from './executions';
import type { ExecutionsState } from './executions';
import groups from './groups';
import type { GroupsState } from './groups';
import logs from './logs';
import type { LogsState } from './logs';
import metadata from './metadata';
import ping, { pingEpic } from './pingPong';
import pollEpic, { apiPollEpic } from './polling';
import projects from './projects';
import type { ProjectsState } from './projects';
import remoteText from './remoteText';
import roles from './roles';
import type { RoleState } from './roles';
import runtime from './runtime';
import sequences from './sequences';
import type { SequencesState } from './sequences';
import setup from './setup';
import type { SharedObjectsState } from './sharedObjects';
import sharedObjects, { objectVersionEpic } from './sharedObjects';
import tasks, { taskVersionEpic } from './tasks';
import type { TaskState } from './tasks';
import ui from './ui';
import type { UiState } from './ui';
import user from './user';
import type { UserState } from './user';
import userManagement from './userManagement';
import type { UserManagementState } from './userManagement';

interface RootState {
  ui: UiState;
  ping: any;
  user: UserState;
  auth: AuthState;
  setup: any;
  tasks: TaskState;
  roles: RoleState;
  admin: any;
  groups: GroupsState;
  engines: EngineState;
  runtime: any;
  metadata: any;
  projects: ProjectsState;
  sequences: SequencesState;
  executions: ExecutionsState;
  connections: ConnectionState;
  sharedObjects: SharedObjectsState;
  userManagement: UserManagementState;
  changePassword: any;
  search: any;
  logs: LogsState;
  dashboards: DashboardsState;
  remoteText: any;
  drawer: DrawerState;
}

const rootEpic = combineEpics(
  pingEpic,
  pollEpic,
  apiPollEpic,
  taskVersionEpic,
  objectVersionEpic,
  fetchParameterCategoriesEpic,
  fetchEngineCategoriesEpic,
);

const rootReducer = combineReducers<RootState>({
  ui,
  ping,
  user,
  auth,
  setup,
  tasks,
  roles,
  admin,
  groups,
  engines,
  runtime,
  metadata,
  [connectionVersionSlice.name]: connectionVersionSlice.reducer,
  projects,
  sequences,
  executions,
  connections,
  sharedObjects,
  userManagement,
  changePassword,
  search: searchReducer,
  logs,
  dashboards,
  remoteText,
  drawer,
});

export type { RootState };
export { rootReducer, rootEpic };
