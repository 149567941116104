const LOAD_DEFAULT_ACTION_VALUES = 'LOAD_DEFAULT_ACTION_VALUES';
const LOAD_ENGINES_FAILURE = 'LOAD_ENGINES_FAILURE';
const ENGINE_CATEGORIES_SUCCESS = 'ENGINE_CATEGORIES_SUCCESS';
const FETCH_ENGINE_CATEGORIES = 'FETCH_ENGINE_CATEGORIES';
const FETCH_ENGINE_CATEGORIES_CANCELLED = 'FETCH_ENGINE_CATEGORIES_CANCELLED';
const FETCH_ENGINE_CATEGORIES_REJECTED = 'FETCH_ENGINE_CATEGORIES_REJECTED';

const PARAMETER_CATEGORIES_SUCCESS = 'PARAMETER_CATEGORIES_SUCCESS';
const FETCH_PARAMETER_CATEGORIES = 'FETCH_PARAMETER_CATEGORIES';
const FETCH_PARAMETER_CATEGORIES_CANCELLED =
  'FETCH_PARAMETER_CATEGORIES_CANCELLED';
const FETCH_PARAMETER_CATEGORIES_REJECTED =
  'FETCH_PARAMETER_CATEGORIES_REJECTED';

const LOAD_ENGINE_REQUEST = 'LOAD_ENGINE_REQUEST';
const LOAD_ENGINE_FAILURE = 'LOAD_ENGINE_FAILURE';
const LOAD_ENGINE_SUCCESS = 'LOAD_ENGINE_SUCCESS';
const LOAD_ENGINE_TYPE_REQUEST = 'LOAD_ENGINE_TYPE_REQUEST';
const LOAD_ENGINE_TYPE_SUCCESS = 'LOAD_ENGINE_TYPE_SUCCESS';
const LOAD_ENGINES_REQUEST = 'LOAD_ENGINES_REQUEST';
const LOAD_ENGINES_SUCCESS = 'LOAD_ENGINES_SUCCESS';

const CLEAR_CATEGORY_FILTER = 'CLEAR_CATEGORY_FILTER';
const SET_CATEGORY_FILTER = 'SET_CATEGORY_FILTER';

const CLEAR_PARAMETER_CATEGORY_FILTER = 'CLEAR_PARAMETER_CATEGORY_FILTER';
const SET_PARAMETER_CATEGORY_FILTER = 'SET_PARAMETER_CATEGORY_FILTER';

export {
  CLEAR_CATEGORY_FILTER,
  SET_CATEGORY_FILTER,
  LOAD_ENGINE_REQUEST,
  LOAD_ENGINE_FAILURE,
  LOAD_ENGINE_SUCCESS,
  LOAD_ENGINE_TYPE_REQUEST,
  LOAD_ENGINE_TYPE_SUCCESS,
  LOAD_ENGINES_REQUEST,
  LOAD_ENGINES_SUCCESS,
  LOAD_DEFAULT_ACTION_VALUES,
  LOAD_ENGINES_FAILURE,
  ENGINE_CATEGORIES_SUCCESS,
  FETCH_ENGINE_CATEGORIES,
  FETCH_ENGINE_CATEGORIES_CANCELLED,
  FETCH_ENGINE_CATEGORIES_REJECTED,
  PARAMETER_CATEGORIES_SUCCESS,
  FETCH_PARAMETER_CATEGORIES,
  FETCH_PARAMETER_CATEGORIES_CANCELLED,
  FETCH_PARAMETER_CATEGORIES_REJECTED,
  CLEAR_PARAMETER_CATEGORY_FILTER,
  SET_PARAMETER_CATEGORY_FILTER,
};
