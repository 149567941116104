import { uniq } from 'lodash';
import {
  LOAD_TEXT_REQUEST,
  LOAD_TEXT_SUCCESS,
  LOAD_TEXT_FAILURE,
} from '../constants';

// Initial State
const initialState = {
  data: {},
  urls: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TEXT_REQUEST:
      return {
        ...state,
        urls: uniq([...state.urls, action.url]),
        data: { ...state.data, [action.url]: { isFetching: true, text: '' } },
      };
    case LOAD_TEXT_SUCCESS:
      return {
        ...state,
        urls: uniq([...state.urls, action.url]),
        data: {
          ...state.data,
          [action.url]: { isFetching: false, text: action.text },
        },
      };

    case LOAD_TEXT_FAILURE:
      return {
        ...state,
        urls: uniq([...state.urls, action.url]),
        data: {
          ...state.data,
          [action.url]: { isFetching: false, text: '', error: action.error },
        },
      };
    default:
      return state;
  }
};

export default reducer;
