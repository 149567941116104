export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const CHECK_INITIALIZED_REQUEST = 'CHECK_INITIALIZED_REQUEST';
export const CHECK_INITIALIZED_FAILURE = 'CHECK_INITIALIZED_FAILURE';
export const CHECK_INITIALIZED_SUCCESS = 'CHECK_INITIALIZED_SUCCESS';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const LOAD_USERS_REQUEST = 'LOAD_USERS_REQUEST';
export const LOAD_USERS_FAILURE = 'LOAD_USERS_FAILURE';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';

export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST';
export const LOAD_USER_FAILURE = 'LOAD_USER_FAILURE';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_CLEAR = 'REGISTER_USER_CLEAR';
export const REGISTER_USER_STEP = 'REGISTER_USER_STEP';
export const REGISTER_USER_EDIT = 'REGISTER_USER_EDIT';
export const VALIDATE_USER_FAILURE = 'VALIDATE_USER_FAILURE';

export const LOAD_ROLES_REQUEST = 'LOAD_ROLES_REQUEST';
export const LOAD_ROLES_FAILURE = 'LOAD_ROLES_FAILURE';
export const LOAD_ROLES_SUCCESS = 'LOAD_ROLES_SUCCESS';

export const LOAD_ROLE_REQUEST = 'LOAD_ROLE_REQUEST';
export const LOAD_ROLE_FAILURE = 'LOAD_ROLE_FAILURE';
export const LOAD_ROLE_SUCCESS = 'LOAD_ROLE_SUCCESS';

export const CREATE_ROLE_REQUEST = 'CREATE_ROLE_REQUEST';
export const CREATE_ROLE_FAILURE = 'CREATE_ROLE_FAILURE';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';

export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST';
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';

export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST';
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';

export const LOAD_GROUP_SUCCESS = 'LOAD_GROUP_SUCCESS';
export const LOAD_GROUP_FAILURE = 'LOAD_GROUP_FAILURE';
export const LOAD_GROUP_REQUEST = 'LOAD_GROUP_REQUEST';
export const LOAD_GROUPS_SUCCESS = 'LOAD_GROUPS_SUCCESS';
export const LOAD_GROUPS_FAILURE = 'LOAD_GROUPS_FAILURE';
export const LOAD_GROUPS_REQUEST = 'LOAD_GROUPS_REQUEST';

export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';

export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST';
export const CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';

export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';

export const SET_GROUP_FILTER = 'SET_GROUP_FILTER';
export const SET_PROJECT_FILTER = 'SET_PROJECT_FILTER';

export const LOAD_VERSIONS_REQUEST = 'LOAD_VERSIONS_REQUEST';
export const LOAD_VERSIONS_FAILURE = 'LOAD_VERSIONS_FAILURE';
export const LOAD_VERSIONS_SUCCESS = 'LOAD_VERSIONS_SUCCESS';

export const LOAD_CONFIG_REQUEST = 'LOAD_CONFIG_REQUEST';
export const LOAD_CONFIG_FAILURE = 'LOAD_CONFIG_FAILURE';
export const LOAD_CONFIG_SUCCESS = 'LOAD_CONFIG_SUCCESS';

export const EDIT_CONFIG_REQUEST = 'EDIT_CONFIG_REQUEST';
export const EDIT_CONFIG_FAILURE = 'EDIT_CONFIG_FAILURE';
export const EDIT_CONFIG_SUCCESS = 'EDIT_CONFIG_SUCCESS';

export const EDIT_PASSWORD_REQUEST = 'EDIT_PASSWORD_REQUEST';
export const EDIT_PASSWORD_FAILURE = 'EDIT_PASSWORD_FAILURE';
export const EDIT_PASSWORD_SUCCESS = 'EDIT_PASSWORD_SUCCESS';
export const EDIT_PASSWORD_CLEAR = 'EDIT_PASSWORD_CLEAR';

export const EXECUTION_LOG_STREAM_START = 'EXECUTION_LOG_STREAM_START';
export const EXECUTION_LOG_STREAM_UPDATE = 'EXECUTION_LOG_STREAM_UPDATE';
export const EXECUTION_LOG_STREAM_CLOSE = 'EXECUTION_LOG_STREAM_CLOSE';
export const SYSTEM_LOG_STREAM_START = 'SYSTEM_LOG_STREAM_START';
export const SYSTEM_LOG_STREAM_UPDATE = 'SYSTEM_LOG_STREAM_UPDATE';
export const SYSTEM_LOG_STREAM_CLOSE = 'SYSTEM_LOG_STREAM_CLOSE';

export const REQUEST_CURRENT_USER = 'REQUEST_CURRENT_USER';
export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';
export const CURRENT_USER_FAILURE = 'CURRENT_USER_FAILURE';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';

export const EDIT_CURRENT_USER_FAILURE = 'EDIT_CURRENT_USER_FAILURE';
export const EDIT_CURRENT_USER_SUCCESS = 'EDIT_CURRENT_USER_SUCCESS';
export const EDIT_CURRENT_USER_REQUEST = 'EDIT_CURRENT_USER_REQUEST';

export const LOAD_TASK_REQUEST = 'LOAD_TASK_REQUEST';
export const LOAD_TASK_FAILURE = 'LOAD_TASK_FAILURE';
export const LOAD_TASK_SUCCESS = 'LOAD_TASK_SUCCESS';

export const CREATE_TASK_REQUEST = 'CREATE_TASK_REQUEST';
export const CREATE_TASK_FAILURE = 'CREATE_TASK_FAILURE';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';

export const UPDATE_TASK_REQUEST = 'UPDATE_TASK_REQUEST';
export const UPDATE_TASK_FAILURE = 'UPDATE_TASK_FAILURE';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';

export const DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST';
export const DELETE_TASK_FAILURE = 'DELETE_TASK_FAILURE';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';

export const TASK_VERSIONS_REQUEST = 'TASK_VERSIONS_REQUEST';
export const TASK_VERSIONS_SUCCESS = 'TASK_VERSIONS_SUCCESS';
export const TASK_VERSIONS_REJECTED = 'TASK_VERSIONS_REJECTED';
export const TASK_VERSIONS_CANCELLED = 'TASKT_VERSIONS_CANCELLED';

export const ACTION_CHANGE = 'ACTION_CHANGE';
export const SET_ACTION_PARAMETERS = 'SET_ACTION_PARAMETERS';
export const CLEAR_ACTION_PARAMETERS = 'CLEAR_ACTION_PARAMETERS';

export const TASK_CHANNEL_CHANGE = 'TASK_CHANNEL_CHANGE';

export const TASK_DETAIL_REQUEST = 'TASK_DETAIL_REQUEST';
export const TASK_DETAIL_FAILURE = 'TASK_DETAIL_FAILURE';
export const TASK_DETAIL_SUCCESS = 'TASK_DETAIL_SUCCESS';

export const LOAD_TASKS_REQUEST = 'LOAD_TASKS_REQUEST';
export const LOAD_TASKS_FAILURE = 'LOAD_TASKS_FAILURE';
export const LOAD_TASKS_SUCCESS = 'LOAD_TASKS_SUCCESS';
export const RESET_TASKS_PAGE = 'RESET_TASKS_PAGE';

export const LOAD_EXECUTIONS_REQUEST = 'LOAD_EXECUTIONS_REQUEST';
export const LOAD_EXECUTIONS_FAILURE = 'LOAD_EXECUTIONS_FAILURE';
export const LOAD_EXECUTIONS_SUCCESS = 'LOAD_EXECUTIONS_SUCCESS';
export const LOAD_EXECUTION_REQUEST = 'LOAD_EXECUTION_REQUEST';
export const LOAD_EXECUTION_FAILURE = 'LOAD_EXECUTION_FAILURE';
export const LOAD_EXECUTION_SUCCESS = 'LOAD_EXECUTION_SUCCESS';
export const RESET_EXECUTION_FILTERS = 'RESET_EXECUTION_FILTERS';

export const LOAD_EXECUTION_RESULT_REQUEST = 'LOAD_EXECUTION_RESULT_REQUEST';
export const LOAD_EXECUTION_RESULT_SUCCESS = 'LOAD_EXECUTION_RESULT_SUCCESS';
export const LOAD_EXECUTION_RESULT_FAILURE = 'LOAD_EXECUTION_RESULT_FAILURE';

export const LOAD_EXECUTION_STATS_PLOT_REQUEST =
  'LOAD_EXECUTION_STATS_PLOT_REQUEST';
export const LOAD_EXECUTION_STATS_PLOT_SUCCESS =
  'LOAD_EXECUTION_STATS_PLOT_SUCCESS';
export const LOAD_EXECUTION_STATS_PLOT_FAILURE =
  'LOAD_EXECUTION_STATS_PLOT_FAILURE';

export const EXECUTE_TASK_SUCCESS = 'EXECUTE_TASK_SUCCESS';
export const EXECUTE_TASK_FAILURE = 'EXECUTE_TASK_FAILURE';
export const EXECUTE_TASK_REQUEST = 'EXECUTE_TASK_REQUEST';
export const KILL_TASK_REQUEST = 'KILL_TASK_REQUEST';
export const KILL_TASK_FAILURE = 'KILL_TASK_FAILURE';
export const KILL_TASK_SUCCESS = 'KILL_TASK_SUCCESS';

export const LOAD_METADATA_REQUEST = 'LOAD_METADATA_REQUEST';
export const LOAD_METADATA_FAILURE = 'LOAD_METADATA_FAILURE';
export const LOAD_METADATA_SUCCESS = 'LOAD_METADATA_SUCCESS';

export const CREATE_METADATA_TABLE_REQUEST = 'CREATE_METADATA_TABLE_REQUEST';
export const CREATE_METADATA_TABLE_FAILURE = 'CREATE_METADATA_TABLE_FAILURE';
export const CREATE_METADATA_TABLE_SUCCESS = 'CREATE_METADATA_TABLE_SUCCESS';

export const SELECT_METADATA_CONNECTION = 'SELECT_METADATA_CONNECTION';

export const LOAD_METADATA_TABLE_REQUEST = 'LOAD_METADATA_TABLE_REQUEST';
export const LOAD_METADATA_TABLE_FAILURE = 'LOAD_METADATA_TABLE_FAILURE';
export const LOAD_METADATA_TABLE_SUCCESS = 'LOAD_METADATA_TABLE_SUCCESS';

export const CREATE_SEQUENCE_REQUEST = 'CREATE_SEQUENCE_REQUEST';
export const CREATE_SEQUENCE_FAILURE = 'CREATE_SEQUENCE_FAILURE';
export const CREATE_SEQUENCE_SUCCESS = 'CREATE_SEQUENCE_SUCCESS';
export const PATCH_SEQUENCE_REQUEST = 'PATCH_SEQUENCE_REQUEST';
export const PATCH_SEQUENCE_FAILURE = 'PATCH_SEQUENCE_FAILURE';
export const PATCH_SEQUENCE_SUCCESS = 'PATCH_SEQUENCE_SUCCESS';

export const UPDATE_SEQUENCE_REQUEST = 'UPDATE_SEQUENCE_REQUEST';
export const UPDATE_SEQUENCE_FAILURE = 'UPDATE_SEQUENCE_FAILURE';
export const UPDATE_SEQUENCE_SUCCESS = 'UPDATE_SEQUENCE_SUCCESS';

export const KILL_SEQUENCE_REQUEST = 'KILL_SEQUENCE_REQUEST';
export const KILL_SEQUENCE_FAILURE = 'KILL_SEQUENCE_FAILURE';
export const KILL_SEQUENCE_SUCCESS = 'KILL_SEQUENCE_SUCCESS';

export const DELETE_SEQUENCE_REQUEST = 'DELETE_SEQUENCE_REQUEST';
export const DELETE_SEQUENCE_FAILURE = 'DELETE_SEQUENCE_FAILURE';
export const DELETE_SEQUENCE_SUCCESS = 'DELETE_SEQUENCE_SUCCESS';

export const LOAD_SEQUENCE_REQUEST = 'LOAD_SEQUENCE_REQUEST';
export const LOAD_SEQUENCE_FAILURE = 'LOAD_SEQUENCE_FAILURE';
export const LOAD_SEQUENCE_SUCCESS = 'LOAD_SEQUENCE_SUCCESS';
export const LOAD_SEQUENCE_RESET = 'LOAD_SEQUENCE_RESET';

export const LOAD_SEQUENCES_REQUEST = 'LOAD_SEQUENCES_REQUEST';
export const LOAD_SEQUENCES_FAILURE = 'LOAD_SEQUENCES_FAILURE';
export const LOAD_SEQUENCES_SUCCESS = 'LOAD_SEQUENCES_SUCCESS';
export const RUN_SEQUENCE_REQUEST = 'RUN_SEQUENCE_REQUEST';
export const RUN_SEQUENCE_FAILURE = 'RUN_SEQUENCE_FAILURE';
export const RUN_SEQUENCE_SUCCESS = 'RUN_SEQUENCE_SUCCESS';

export const LOAD_SEQUENCE_EXECUTIONS_REQUEST =
  'LOAD_SEQUENCE_EXECUTIONS_REQUEST';
export const LOAD_SEQUENCE_EXECUTIONS_FAILURE =
  'LOAD_SEQUENCE_EXECUTIONS_FAILURE';
export const LOAD_SEQUENCE_EXECUTIONS_SUCCESS =
  'LOAD_SEQUENCE_EXECUTIONS_SUCCESS';
export const LOAD_SEQUENCE_TASK_EXECUTIONS_REQUEST =
  'LOAD_SEQUENCE_TASK_EXECUTIONS_REQUEST';
export const LOAD_SEQUENCE_TASK_EXECUTIONS_FAILURE =
  'LOAD_SEQUENCE_TASK_EXECUTIONS_FAILURE';
export const LOAD_SEQUENCE_TASK_EXECUTIONS_SUCCESS =
  'LOAD_SEQUENCE_TASK_EXECUTIONS_SUCCESS';

export const SHOW_ANY = 'Any';
export const SHOW_COMPLETED = 'Completed';
export const SHOW_FAILURE = 'Failure';
export const SHOW_EXECUTING = 'Executing';

export const SET_LOCAL_DB = 'SET_LOCAL_DB';
export const SET_LOCAL_ES = 'SET_LOCAL_ES';
export const SET_CONFIG_ITEM = 'SET_CONFIG_ITEM';
export const REQUEST_USER_ORGANIZATIONS = 'REQUEST_USER_ORGANIZATIONS';
export const REQUEST_USER_ORGANIZATIONS_SUCCESS =
  'REQUEST_USER_ORGANIZATIONS_SUCCESS';
export const REQUEST_USER_ORGANIZATIONS_FAILURE =
  'REQUEST_USER_ORGANIZATIONS_FAILURE';

export const REQUEST_SETUP_LOGIN = 'REQUEST_SETUP_LOGIN';
export const SETUP_LOGIN_SUCCESS = 'SETUP_LOGIN_SUCCESS';
export const SETUP_LOGIN_FAILURE = 'SETUP_LOGIN_FAILURE';

export const REQUEST_METADATA_DETAILS = 'REQUEST_METADATA_DETAILS';
export const METADATA_DETAILS_FAILURE = 'METADATA_DETAILS_FAILURE';
export const METADATA_DETAILS_SUCCESS = 'METADATA_DETAILS_SUCCESS';

export const METADATA_DETAILS_SELECT = 'METADATA_DETAILS_SELECT';
export const CREATE_METADATA_COLUMN_REQUEST = 'CREATE_METADATA_COLUMN_REQUEST';
export const CREATE_METADATA_COLUMN_FAILURE = 'CREATE_METADATA_COLUMN_FAILURE';
export const CREATE_METADATA_COLUMN_SUCCESS = 'CREATE_METADATA_COLUMN_SUCCESS';

export const UPDATE_METADATA_TABLE_REQUEST = 'UPDATE_METADATA_TABLE_REQUEST';
export const UPDATE_METADATA_TABLE_FAILURE = 'UPDATE_METADATA_TABLE_FAILURE';
export const UPDATE_METADATA_TABLE_SUCCESS = 'UPDATE_METADATA_TABLE_SUCCESS';

export const UPDATE_METADATA_COLUMN_REQUEST = 'UPDATE_METADATA_COLUMN_REQUEST';
export const UPDATE_METADATA_COLUMN_FAILURE = 'UPDATE_METADATA_COLUMN_FAILURE';
export const UPDATE_METADATA_COLUMN_SUCCESS = 'UPDATE_METADATA_COLUMN_SUCCESS';

export const DELETE_METADATA_COLUMN_REQUEST = 'DELETE_METADATA_COLUMN_REQUEST';
export const DELETE_METADATA_COLUMN_FAILURE = 'DELETE_METADATA_COLUMN_FAILURE';
export const DELETE_METADATA_COLUMN_SUCCESS = 'DELETE_METADATA_COLUMN_SUCCESS';

export const LOAD_PROJECT_SUCCESS = 'LOAD_PROJECT_SUCCESS';
export const LOAD_PROJECT_FAILURE = 'LOAD_PROJECT_FAILURE';
export const LOAD_PROJECT_REQUEST = 'LOAD_PROJECT_REQUEST';
export const LOAD_PROJECTS_SUCCESS = 'LOAD_PROJECTS_SUCCESS';
export const LOAD_PROJECTS_FAILURE = 'LOAD_PROJECTS_FAILURE';
export const LOAD_PROJECTS_REQUEST = 'LOAD_PROJECTS_REQUEST';
export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST';
export const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';

export const LOAD_DASHBOARDS_SUCCESS = 'LOAD_DASHBOARDS_SUCCESS';
export const LOAD_DASHBOARDS_FAILURE = 'LOAD_DASHBOARDS_FAILURE';
export const LOAD_DASHBOARDS_REQUEST = 'LOAD_DASHBOARDS_REQUEST';

export const CREATE_DASHBOARD_SUCCESS = 'CREATE_DASHBOARD_SUCCESS';
export const CREATE_DASHBOARD_FAILURE = 'CREATE_DASHBOARD_FAILURE';
export const CREATE_DASHBOARD_REQUEST = 'CREATE_DASHBOARD_REQUEST';

export const DELETE_DASHBOARD_SUCCESS = 'DELETE_DASHBOARD_SUCCESS';
export const DELETE_DASHBOARD_FAILURE = 'DELETE_DASHBOARD_FAILURE';
export const DELETE_DASHBOARD_REQUEST = 'DELETE_DASHBOARD_REQUEST';

export const LOAD_DASHBOARD_ITEMS_SUCCESS = 'LOAD_DASHBOARD_ITEMS_SUCCESS';
export const LOAD_DASHBOARD_ITEMS_FAILURE = 'LOAD_DASHBOARD_ITEMS_FAILURE';
export const LOAD_DASHBOARD_ITEMS_REQUEST = 'LOAD_DASHBOARD_ITEMS_REQUEST';

export const UPDATE_DASHBOARD_ITEM_SUCCESS = 'UPDATE_DASHBOARD_ITEM_SUCCESS';
export const UPDATE_DASHBOARD_ITEM_FAILURE = 'UPDATE_DASHBOARD_ITEM_FAILURE';
export const UPDATE_DASHBOARD_ITEM_REQUEST = 'UPDATE_DASHBOARD_ITEM_REQUEST';

export const CREATE_DASHBOARD_ITEM_SUCCESS = 'CREATE_DASHBOARD_ITEM_SUCCESS';
export const CREATE_DASHBOARD_ITEM_FAILURE = 'CREATE_DASHBOARD_ITEM_FAILURE';
export const CREATE_DASHBOARD_ITEM_REQUEST = 'CREATE_DASHBOARD_ITEM_REQUEST';

export const DELETE_DASHBOARD_ITEM_SUCCESS = 'DELETE_DASHBOARD_ITEM_SUCCESS';
export const DELETE_DASHBOARD_ITEM_FAILURE = 'DELETE_DASHBOARD_ITEM_FAILURE';
export const DELETE_DASHBOARD_ITEM_REQUEST = 'DELETE_DASHBOARD_ITEM_REQUEST';

export const SET_CURRENT_DASHBOARD = 'SET_CURRENT_DASHBOARD';

export const POLL_END = 'POLL_END';
export const POLL_START = 'POLL_START';
export const POLL_ERROR = 'POLL_ERROR';
export const POLL_RECEIVED = 'POLL_RECEIVED';

export const API_STATUS_ERROR = 'API_STATUS_ERROR';
export const API_STATUS_RECEIVED = 'API_STATUS_RECEIVED';

export const LOAD_TEXT_REQUEST = 'LOAD_TEXT_REQUEST';
export const LOAD_TEXT_FAILURE = 'LOAD_TEXT_FAILURE';
export const LOAD_TEXT_SUCCESS = 'LOAD_TEXT_SUCCESS';

export const SET_DRAWER_VISIBLE = 'SET_DRAWER_VISIBLE';
export const SET_DRAWER_DETAILS = 'SET_DRAWER_DETAILS';
export const RESET_DRAWER = 'RESET_DRAWER';

export const TOGGLE_MODAL = 'TOGGLE_MODAL';
