import { uniq, omit } from 'lodash';
import { Project } from '../types/project';
import {
  LOAD_PROJECT_REQUEST,
  LOAD_PROJECT_FAILURE,
  LOAD_PROJECT_SUCCESS,
  LOAD_PROJECTS_REQUEST,
  LOAD_PROJECTS_FAILURE,
  LOAD_PROJECTS_SUCCESS,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_FAILURE,
  CREATE_PROJECT_SUCCESS,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_FAILURE,
  DELETE_PROJECT_SUCCESS,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_FAILURE,
  UPDATE_PROJECT_SUCCESS,
  SET_CURRENT_PROJECT,
} from '../constants';

export type ProjectsState = {
  error?: any,
  ids: string[],
  project: Project,
  isFetching: boolean,
  isSubmitting: boolean,
  currentProject: string,
  entities: { [key: string]: Project },
};

// Initial State
const initialState: ProjectsState = {
  ids: [],
  error: null,
  project: {},
  entities: {},
  isFetching: false,
  isSubmitting: false,
  currentProject: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PROJECT:
      return { ...state, currentProject: action.payload };

    case LOAD_PROJECT_REQUEST:
      return { ...state, project: {}, isFetching: true, error: null };

    case CREATE_PROJECT_SUCCESS:
    case UPDATE_PROJECT_SUCCESS:
    case LOAD_PROJECT_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.result]:
            action.payload.entities[action.payload.result],
        },
        ids: uniq([...state.ids, action.payload.result]),
        isFetching: false,
        isSubmitting: false,
      };

    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        entities: omit(state.entities, action.payload.uuid),
        ids: state.ids.filter((id) => action.payload.uuid !== id),
        isFetching: false,
        isSubmitting: false,
      };

    case LOAD_PROJECTS_REQUEST:
      return { ...state, isFetching: true, error: null };

    case LOAD_PROJECTS_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.entities,
        },
        ids: uniq([...state.ids, ...action.payload.ids]),
        isFetching: false,
      };

    case LOAD_PROJECT_FAILURE:
    case LOAD_PROJECTS_FAILURE:
      return {
        ...state,
        error: action.payload || action.error,
        timestamp: action.timestamp,
        isFetching: false,
      };

    case CREATE_PROJECT_REQUEST:
    case DELETE_PROJECT_REQUEST:
    case UPDATE_PROJECT_REQUEST:
      return { ...state, isSubmitting: true, error: null };

    case CREATE_PROJECT_FAILURE:
    case DELETE_PROJECT_FAILURE:
    case UPDATE_PROJECT_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: action.payload || action.error,
      };

    default:
      return state;
  }
};

// Export Reducer
export default reducer;
