import { schema } from 'normalizr';
import * as PropTypes from 'prop-types';

import { CategoriesProp, ParameterProp } from './parameters';
import type { Parameters } from './parameters';

type SharedObjectParameters = Parameters & {
  sharedParameterGroupUuid?: string,
};

type ObjectType = {
  alias: string,
  categories: Array<{ name: string }>,
  created: string,
  description: string,
  disabled: boolean | null,
  nnd_model_version: number,
  parameters: SharedObjectParameters[],
  updated: string,
  uuid: string,
};

type SharedObject = {
  uuid: string,
  alias: string,
  sharedObjectTypeUuid?: string,
  description?: string,
  created: string,
  notify: any,
  options: any,
  parameters: SharedObjectParameters,
  sharedObjectType?: string | ObjectType,
  status: null,
  token: string,
  updated: string,
};

interface SharedConfig extends SharedObject {
  sharedConfigTypeUuid: string;
  sharedConfigType: ObjectType;
}

interface SharedField extends SharedObject {
  fieldType: string;
}

type SharedObjectVersion = SharedObject & {
  aliasMod: boolean,
  categoriesMod: boolean,
  commentsMod: boolean,
  createdMod: boolean,
  descriptionMod: boolean,
  notifyMod: boolean,
  operationType: 0 | 1 | 2,
  optionsMod: boolean,
  parametersMod: boolean,
  projectUuidMod: boolean,
  sharedObjectTypeUuidMod: boolean,
  statusMod: boolean,
  tokenMod: boolean,
  transactionId: number,
  updatedMod: boolean,
  versionParent: string,
  version: number,
};

export const SharedTypeProps = PropTypes.shape({
  alias: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  categories: CategoriesProp,
  disabled: PropTypes.bool,
  parameters: PropTypes.arrayOf(ParameterProp.isRequired).isRequired,
  created: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
});

const SharedObjectProps = {
  uuid: PropTypes.string.isRequired,
  alias: PropTypes.string.isRequired,
  sharedObjectTypeUuid: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  notify: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  parameters: PropTypes.object.isRequired,
  sharedObjectType: PropTypes.oneOfType([PropTypes.string, SharedTypeProps]),
  status: PropTypes.array,
  token: PropTypes.string,
  updated: PropTypes.string.isRequired,
};

const sharedConfigTypeSchema = new schema.Entity(
  'sharedConfigTypes',
  {},
  {
    idAttribute: 'uuid',
  },
);

export const sharedConfigSchema = new schema.Entity(
  'sharedConfigs',
  {
    sharedConfigType: sharedConfigTypeSchema,
  },
  {
    idAttribute: 'uuid',
  },
);

export const sharedFieldSchema = new schema.Entity(
  'sharedFields',
  {
    // sharedObjectType: sharedFieldTypeSchema,
  },
  {
    idAttribute: 'uuid',
  },
);

const sharedObjectTypeSchema = new schema.Entity(
  'sharedObjectTypes',
  {},
  {
    idAttribute: (sharedObjectType) => sharedObjectType.uuid,
  },
);

export const sharedObjectSchema = new schema.Entity(
  'sharedObjects',
  {
    sharedObjectType: sharedObjectTypeSchema,
  },
  {
    idAttribute: (sharedObject) => sharedObject.uuid,
  },
);

// arrayOfSharedConfigs
const arrayOfSharedConfigs = [sharedConfigSchema];
const arrayOfSharedConfigTypes = [sharedConfigTypeSchema];

// arrayOfSharedFields
const arrayOfSharedFields = [sharedFieldSchema];

// arrayOfSharedObjects
const arrayOfSharedObjects = [sharedObjectSchema];
const arrayOfSharedObjectTypes = [sharedObjectTypeSchema];

export type { SharedObject, SharedConfig, SharedField, SharedObjectVersion };
export {
  SharedObjectProps,
  arrayOfSharedConfigs,
  arrayOfSharedFields,
  arrayOfSharedObjects,
  arrayOfSharedConfigTypes,
  arrayOfSharedObjectTypes,
};
