import { combineReducers } from 'redux';
import type { Reducer } from 'redux';
import { TOGGLE_MODAL } from '../constants';

const TASK_CREATE = 'TASK_CREATE';
const GROUP_CREATE = 'GROUP_CREATE';
const PROJECT_CREATE = 'PROJECT_CREATE';
const METADATA_CREATE = 'METADATA_CREATE';
const CONNECTION_CREATE = 'CONNECTION_CREATE';
const SHARED_CONFIG_CREATE = 'SHARED_CONFIG_CREATE';
const SHARED_FIELD_CREATE = 'SHARED_FIELD_CREATE';
const SHARED_OBJECT_CREATE = 'SHARED_OBJECT_CREATE';
const SHARED_FIELD_SELECT = 'SHARED_FIELD_SELECT';

const MODAL_TYPES = {
  TASK_CREATE,
  GROUP_CREATE,
  PROJECT_CREATE,
  METADATA_CREATE,
  CONNECTION_CREATE,
  SHARED_OBJECT_CREATE,
  SHARED_CONFIG_CREATE,
  SHARED_FIELD_CREATE,
  SHARED_FIELD_SELECT,
};

const MODAL_CONSTANTS = {
  [TASK_CREATE]: { displayText: 'Create Task' },
  [GROUP_CREATE]: { displayText: 'Create Group' },
  [PROJECT_CREATE]: { displayText: 'Create Project' },
  [METADATA_CREATE]: { displayText: 'Create Metadata Table' },
  [CONNECTION_CREATE]: { displayText: 'Create Connection' },
  [SHARED_CONFIG_CREATE]: { displayText: 'Create Shared Config' },
  [SHARED_FIELD_CREATE]: { displayText: 'Create Shared Field' },
  [SHARED_FIELD_SELECT]: { displayText: 'Select Shared Field' },
  [SHARED_OBJECT_CREATE]: { displayText: 'Create Shared Object' },
};

type ModalType = typeof MODAL_TYPES;
type ModalActionName = $Keys<ModalType>;
type ModalActionType = $PropertyType<ModalType>;

type ModalState = {
  TASK_CREATE: boolean,
  GROUP_CREATE: boolean,
  PROJECT_CREATE: boolean,
  METADATA_CREATE: boolean,
  CONNECTION_CREATE: boolean,
  SHARED_CONFIG_CREATE: boolean,
  SHARED_FIELD_CREATE: boolean,
  SHARED_OBJECT_CREATE: boolean,
  SHARED_FIELD_SELECT: boolean,
};

type UiState = { modals: ModalState };

interface ModalAction {
  type: typeof TOGGLE_MODAL;
  payload: {
    type: ModalActionType,
    value: boolean,
  };
}

function getModalData(
  type: ModalActionName,
): $PropertyType<typeof MODAL_CONSTANTS> {
  return MODAL_CONSTANTS[type] || {};
}

const initialState = {
  [TASK_CREATE]: false,
  [GROUP_CREATE]: false,
  [PROJECT_CREATE]: false,
  [METADATA_CREATE]: false,
  [CONNECTION_CREATE]: false,
  [SHARED_CONFIG_CREATE]: false,
  [SHARED_FIELD_CREATE]: false,
  [SHARED_OBJECT_CREATE]: false,
  [SHARED_FIELD_SELECT]: false,
};

const modals: Reducer<ModalState, ModalAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        [action.payload.type]: action.payload.value,
      };

    default:
      return state;
  }
};

export { getModalData, MODAL_CONSTANTS, MODAL_TYPES };
export type { ModalState, UiState };
export default combineReducers<UiState>({ modals });
