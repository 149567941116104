import PropTypes from 'prop-types';

type Entities<T = any> = { [id: string]: T };

type Parameters = {
  description: string,
  displayName: string,
  max: number,
  name: string,
  required: boolean,
  type: string,
  parameters?: Parameters[],
};

const CategoriesProp = PropTypes.arrayOf(
  PropTypes.shape({ name: PropTypes.string.isRequired }),
);

const ParameterProp = PropTypes.shape({
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
});

export type { Parameters, Entities };
export { CategoriesProp, ParameterProp };
