import {
  SET_DRAWER_DETAILS,
  SET_DRAWER_VISIBLE,
  RESET_DRAWER,
} from '../constants';

export type DrawerState = {
  visible: boolean,
  scrollTo: String,
  srcList: URL[],
};

// Initial State
const initialDrawerState: DrawerState = {
  visible: false,
  scrollTo: '',
  srcList: [],
};

const reducer = (state = initialDrawerState, action): DrawerState => {
  switch (action.type) {
    case SET_DRAWER_DETAILS:
      return { ...state, ...action.details };
    case SET_DRAWER_VISIBLE:
      return { ...state, visible: action.visible };
    case RESET_DRAWER:
      return initialDrawerState;
    default:
      return state;
  }
};

export default reducer;
