import { combineReducers } from 'redux';
import { omit, uniq } from 'lodash';

import {
  LOAD_METADATA_REQUEST,
  LOAD_METADATA_FAILURE,
  LOAD_METADATA_SUCCESS,
  SELECT_METADATA_CONNECTION,
  LOAD_METADATA_TABLE_REQUEST,
  LOAD_METADATA_TABLE_FAILURE,
  LOAD_METADATA_TABLE_SUCCESS,
  REQUEST_METADATA_DETAILS,
  METADATA_DETAILS_FAILURE,
  METADATA_DETAILS_SUCCESS,
  CREATE_METADATA_COLUMN_SUCCESS,
  UPDATE_METADATA_COLUMN_SUCCESS,
  METADATA_DETAILS_SELECT,
  CREATE_METADATA_TABLE_REQUEST,
  CREATE_METADATA_TABLE_FAILURE,
  CREATE_METADATA_TABLE_SUCCESS,
} from '../constants';

// Initial State
const initialState = { data: [], isFetching: false, selected: {} };

const connectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_METADATA_CONNECTION:
      return { ...state, selected: action.selected };

    case LOAD_METADATA_REQUEST:
      return { ...state, isFetching: true };

    case LOAD_METADATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        timestamp: action.timestamp,
      };

    case LOAD_METADATA_SUCCESS:
      return {
        ...state,
        data: action.metadata,
        selected: action.metadata.length ? action.metadata[0] : {},
        isFetching: false,
      };

    default:
      return state;
  }
};

const initialTablesState = {
  ...initialState,
  selected: null,
  entities: {
    dataTables: {},
    dataTableColumns: {},
  },
  columns: {},
  dataSets: {},
  ids: [],
};

const tablesReducer = (state = initialTablesState, action) => {
  switch (action.type) {
    case METADATA_DETAILS_SELECT:
      return { ...state, selected: action.payload.tableId };

    case METADATA_DETAILS_SUCCESS:
      return {
        ...state,
        ...state,
        entities: {
          ...state.entities,
          dataTables: {
            ...state.entities.dataTables,
            ...action.payload.entities.dataTables,
          },
          dataTableColumns: {
            ...state.entities.dataTableColumns,
            ...action.payload.entities.dataTableColumns,
          },
        },
        ids: uniq([...state.ids, action.payload.result]),
        isFetching: false,
        isSubmitting: false,
      };

    case REQUEST_METADATA_DETAILS:
    case LOAD_METADATA_TABLE_REQUEST:
    case CREATE_METADATA_TABLE_REQUEST:
      return { ...state, isFetching: true };

    case METADATA_DETAILS_FAILURE:
    case CREATE_METADATA_TABLE_FAILURE:
    case LOAD_METADATA_TABLE_FAILURE:
      return {
        ...state,
        timestamp: action.timestamp,
        error: action.error,
        isFetching: false,
      };

    case CREATE_METADATA_TABLE_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          dataTables: {
            ...state.entities.dataTables,
            [action.payload.uuid]: action.payload.dataTable,
          },
        },
        ids: uniq([action.payload.uuid, ...state.ids]),
        isFetching: false,
        isSubmitting: false,
      };
    case LOAD_METADATA_TABLE_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          dataTables: {
            ...state.entities.dataTables,
            ...action.payload.entities.dataTables,
          },
          dataTableColumns: {
            ...state.entities.dataTableColumns,
            ...action.payload.entities.dataTableColumns,
          },
        },
        ids: uniq([...state.ids, ...action.payload.result]),
        isFetching: false,
        isSubmitting: false,
      };

    case CREATE_METADATA_COLUMN_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          dataTables: {
            ...state.entities.dataTables,
            [action.payload.tableId]: {
              ...state.entities.dataTables[action.payload.tableId],
              columns: [
                action.payload.columnData.id,
                ...state.entities.dataTables[action.payload.tableId].columns,
              ],
            },
          },
          dataTableColumns: {
            ...state.entities.dataTableColumns,
            [action.payload.columnData.id]: action.payload.columnData,
          },
        },
        isFetching: false,
        isSubmitting: false,
      };

    case UPDATE_METADATA_COLUMN_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          dataTableColumns: {
            ...state.entities.dataTableColumns,
            [action.payload.columnId]: action.payload.columnData,
          },
        },
        isSubmitting: false,
      };
    case 'DELETE_METADATA_COLUMN_SUCCESS':
      return {
        ...state,
        entities: {
          ...state.entities,
          dataTables: {
            ...state.entities.dataTables,
            [action.payload.tableId]: {
              ...state.entities.dataTables[action.payload.tableId],
              columns: state.entities.dataTables[
                action.payload.tableId
              ].columns.filter((i) => i !== action.payload.columnId),
            },
          },
          dataTableColumns: omit(state.entities.dataTableColumns, [
            action.payload.columnId,
          ]),
        },
        isSubmitting: false,
      };
    default:
      return state;
  }
};

// Get connection by cuid
// export const getSharedObject = (state, cuid) => state.metadata.data.filter(connection => connection.uuid === cuid)[0];

// Export Reducer
export default combineReducers({
  connections: connectionsReducer,
  tables: tablesReducer,
});
