import React from 'react';
import { hot } from 'react-hot-loader/root';
import loadable from '@loadable/component';
import { parse as qsParse } from 'query-string';
import { Redirect, Route, Switch } from 'react-router-dom';
import ScrollTop from 'components/ScrollTop';

const MainRoute = loadable(() =>
  import(/* webpackChunkName: "MainRoute" */ './routes/main'),
);

const AuthRoute = loadable(() =>
  import(/* webpackChunkName: "AuthRoute" */ './routes/auth'),
);

const SetupRoute = loadable(() =>
  import(/* webpackChunkName: "SetupRoute" */ './routes/setup'),
);

function Router() {
  return (
    <ScrollTop>
      <Switch>
        <Route
          exact
          key="nomitall/inbound"
          path="/nomitall/inbound"
          render={(matchParams) => {
            const { redirect } = qsParse(matchParams.location.search);
            return <Redirect to={redirect} />;
          }}
        />
        <Route key="setup" path="/setup" component={SetupRoute} />
        <Route
          exact
          key="login"
          path="/(login|forgot-password)/:user?"
          component={AuthRoute}
        />
        <Route key="dashboard" path="/:appRoute?" component={MainRoute} />
      </Switch>
    </ScrollTop>
  );
}

export default hot(Router);
