import { isArray, isUndefined } from 'lodash';
import { camelCase } from './strings';

/**
 * Set Default of Field
 * @param fieldDef
 */
const getDefault = (fieldDef) => {
  const key = fieldDef.name || fieldDef.parameterName;
  const defaultVal = {};

  if (fieldDef.type === 'connection') {
    defaultVal[camelCase(key)] = !isUndefined(fieldDef.default)
      ? {
          connectionUuid: fieldDef.default,
        }
      : null;
  } else if (typeof fieldDef.default === 'boolean') {
    defaultVal[camelCase(key)] = !isUndefined(fieldDef.default)
      ? fieldDef.default
      : false;
  } else if (fieldDef.type === 'enum_list') {
    defaultVal[camelCase(key)] = !isUndefined(fieldDef.default)
      ? fieldDef.default
      : [];
  } else if (fieldDef.many) {
    defaultVal[camelCase(key)] = !isUndefined(fieldDef.default)
      ? fieldDef.default
      : [];
  } else {
    defaultVal[camelCase(key)] = !isUndefined(fieldDef.default)
      ? fieldDef.default
      : null;
  }
  return defaultVal;
};

/**
 * Array of parameters
 * @param paramArr
 */
export const getDefaultActionParams = (paramArr: any[]) => {
  const initialValues = {};

  const flattenedParams = [];
  paramArr.forEach((item) => {
    if (item.type === 'group' && item.parameters) {
      item.parameters.forEach((subItem) => flattenedParams.push(subItem));
    } else {
      flattenedParams.push(item);
    }
  });

  flattenedParams
    .flat()
    .map(getDefault)
    .forEach((field) => {
      const key = Object.keys(field)[0];
      const value = Object.values(field)[0];
      initialValues[key] = value;
    });
  return initialValues;
};

export const objToArray = (obj) => {
  if (!isArray(obj)) {
    return Object.values(obj).map(({ actionName, parameters }) => ({
      actionName,
      parameters,
    }));
  }
  return obj.map(({ actionName, parameters }) => ({
    actionName,
    parameters,
  }));
};
