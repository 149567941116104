import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

type Props = {
  location: Object,
  children?: React.Node,
};

class ScrollToTop extends Component<Props> {
  static defaultProps = {
    children: null,
  };

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;
    return React.Children.only(children);
  }
}

export default withRouter(ScrollToTop);
