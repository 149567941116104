import { decamelize } from 'humps';
import {
  camelCase as camelize,
  isArray,
  isFunction,
  isObject,
  startCase,
} from 'lodash';
import { isMoment } from 'moment-timezone';

/**
 *
 * @param string
 * @return {string}
 */
function camelCase(string?: string): string {
  return camelize(string);
}

/**
 *
 * @param string
 * @return {string}
 */
function snakeCase(string?: string): string {
  return decamelize(string);
}

interface ConvertFunction {
  (string | boolean | number): string | boolean | number;
}

/**
 * Base object key converter
 * @constructor
 * @param convert - Function applied to all keys.
 * @param obj - Object to apply key conversion.
 * @return {null|any}
 */
function convertKeys(convert: ConvertFunction, obj: any): any | null {
  // if (isUndefined(obj)) return null;

  if (!isObject(obj) && !isArray(obj)) return obj;

  if (isArray(obj)) {
    return obj.map((item) => convertKeys(convert, item));
  }

  if (isMoment(obj) || isFunction(obj)) {
    return obj;
  }

  return Object.fromEntries(
    Object.keys(obj).map((key) => [
      convert(key),
      convertKeys(convert, obj[key]),
    ]),
  );
}

/**
 *
 * @constructor
 * @param obj
 * @return {*}
 */
function camelCaseKeys(obj: any): any {
  return convertKeys(camelCase, obj);
}

function snakeCaseKeys(obj: any): any {
  return convertKeys(snakeCase, obj);
}

function startCaseKeys(obj: any): any {
  return convertKeys(startCase, obj);
}

/**
 * @constructor
 * @param {string} rawText
 * @return {string}
 */
function escapeString(rawText: string | number) {
  if (!rawText) {
    return '';
  }
  try {
    return JSON.stringify(
      rawText
        .toString()
        .replace(/\\n/g, '\\n')
        .replace(/\\'/g, "\\'")
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, '\\&')
        .replace(/\\r/g, '\\r')
        .replace(/\\t/g, '\\t')
        .replace(/\\b/g, '\\b')
        .replace(/\\f/g, '\\f'),
    ).replace(/(^"|"$)/g, '');
  } catch (e) {
    console.error(e);
    return '';
  }
}

function makeIdSafe(str) {
  return str.replace(/\W/g, '_');
}

export {
  makeIdSafe,
  camelCase,
  snakeCase,
  convertKeys,
  camelCaseKeys,
  snakeCaseKeys,
  startCaseKeys,
};
export default escapeString;
