const LOAD_CONNECTION_REQUEST = 'LOAD_CONNECTION_REQUEST';
const LOAD_CONNECTION_FAILURE = 'LOAD_CONNECTION_FAILURE';
const LOAD_CONNECTION_SUCCESS = 'LOAD_CONNECTION_SUCCESS';
const CREATE_CONNECTION_REQUEST = 'CREATE_CONNECTION_REQUEST';
const CREATE_CONNECTION_FAILURE = 'CREATE_CONNECTION_FAILURE';
const CREATE_CONNECTION_SUCCESS = 'CREATE_CONNECTION_SUCCESS';
const UPDATE_CONNECTION_REQUEST = 'UPDATE_CONNECTION_REQUEST';
const UPDATE_CONNECTION_FAILURE = 'UPDATE_CONNECTION_FAILURE';
const UPDATE_CONNECTION_SUCCESS = 'UPDATE_CONNECTION_SUCCESS';
const DELETE_CONNECTION_REQUEST = 'DELETE_CONNECTION_REQUEST';
const DELETE_CONNECTION_FAILURE = 'DELETE_CONNECTION_FAILURE';
const DELETE_CONNECTION_SUCCESS = 'DELETE_CONNECTION_SUCCESS';
const LOAD_CONNECTIONS_REQUEST = 'LOAD_CONNECTIONS_REQUEST';
const LOAD_CONNECTIONS_FAILURE = 'LOAD_CONNECTIONS_FAILURE';
const LOAD_CONNECTIONS_SUCCESS = 'LOAD_CONNECTIONS_SUCCESS';
const LOAD_CONNECTION_TYPES_REQUEST = 'LOAD_CONNECTION_TYPES_REQUEST';
const LOAD_CONNECTION_TYPES_SUCCESS = 'LOAD_CONNECTION_TYPES_SUCCESS';
const CLEAR_CONNECTION_DETAIL = 'CLEAR_CONNECTION_DETAIL';
const CONNECTION_DETAIL_REQUEST = 'CONNECTION_DETAIL_REQUEST';
const CONNECTION_DETAIL_FAILURE = 'CONNECTION_DETAIL_FAILURE';
const CONNECTION_DETAIL_SUCCESS = 'CONNECTION_DETAIL_SUCCESS';
const LOAD_CONNECTION_TYPE_REQUEST = 'LOAD_CONNECTION_TYPE_REQUEST';
const LOAD_CONNECTION_TYPE_SUCCESS = 'LOAD_CONNECTION_TYPE_SUCCESS';
const LOAD_CONNECTION_TYPES_FAILURE = 'LOAD_CONNECTION_TYPES_FAILURE';
const SELECT_CONNECTION_TYPE = 'SELECT_CONNECTION_TYPE';
const LOAD_CONNECTION_TYPE_FAILURE = 'LOAD_CONNECTION_TYPE_FAILURE';
const CONNECTION_VERSIONS_REQUEST = 'CONNECTION_VERSIONS_REQUEST';
const CONNECTION_VERSIONS_CANCELLED = 'CONNECTION_VERSIONS_CANCELLED';
const CONNECTION_VERSIONS_REJECTED = 'CONNECTION_VERSIONS_REJECTED';
const CONNECTION_VERSIONS_SUCCESS = 'CONNECTION_VERSIONS_SUCCESS';

export {
  CLEAR_CONNECTION_DETAIL,
  CONNECTION_DETAIL_FAILURE,
  CONNECTION_DETAIL_REQUEST,
  CONNECTION_DETAIL_SUCCESS,
  CREATE_CONNECTION_FAILURE,
  CREATE_CONNECTION_REQUEST,
  CREATE_CONNECTION_SUCCESS,
  DELETE_CONNECTION_FAILURE,
  DELETE_CONNECTION_REQUEST,
  DELETE_CONNECTION_SUCCESS,
  LOAD_CONNECTIONS_FAILURE,
  LOAD_CONNECTIONS_REQUEST,
  LOAD_CONNECTIONS_SUCCESS,
  LOAD_CONNECTION_FAILURE,
  LOAD_CONNECTION_REQUEST,
  LOAD_CONNECTION_SUCCESS,
  LOAD_CONNECTION_TYPES_FAILURE,
  LOAD_CONNECTION_TYPES_REQUEST,
  LOAD_CONNECTION_TYPES_SUCCESS,
  LOAD_CONNECTION_TYPE_FAILURE,
  LOAD_CONNECTION_TYPE_REQUEST,
  LOAD_CONNECTION_TYPE_SUCCESS,
  SELECT_CONNECTION_TYPE,
  UPDATE_CONNECTION_FAILURE,
  UPDATE_CONNECTION_REQUEST,
  UPDATE_CONNECTION_SUCCESS,
  CONNECTION_VERSIONS_REQUEST,
  CONNECTION_VERSIONS_CANCELLED,
  CONNECTION_VERSIONS_REJECTED,
  CONNECTION_VERSIONS_SUCCESS,
};
