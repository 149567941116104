import type { UserType } from 'common/types/user';
import { Action } from 'common/store/types';
import { Reducer } from 'redux';
import {
  CLEAR_CURRENT_USER,
  CURRENT_USER_FAILURE,
  EDIT_CURRENT_USER_FAILURE,
  EDIT_CURRENT_USER_REQUEST,
  EDIT_CURRENT_USER_SUCCESS,
  RECEIVE_CURRENT_USER,
  REQUEST_CURRENT_USER,
} from '../constants';

interface UserState extends UserType {
  error: string | any | null;
  isEditing: boolean;
  isFetching: boolean;
  hasFetched: boolean;
}

const initialState = {
  error: null,
  isEditing: false,
  hasFetched: false,
  isFetching: false,
};

const reducer: Reducer<UserState, Action> = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_CURRENT_USER:
      return initialState;

    case REQUEST_CURRENT_USER:
      return { ...state, hasFetched: true, isFetching: true, error: null };

    case CURRENT_USER_FAILURE:
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        error: action.error,
        timestamp: action.timestamp,
      };

    case RECEIVE_CURRENT_USER:
      return { ...state, ...action.user, hasFetched: true, isFetching: false };

    case EDIT_CURRENT_USER_REQUEST:
      return { ...state, error: null, isEditing: true };

    case EDIT_CURRENT_USER_SUCCESS:
      return { ...state, isEditing: false, ...action.user };

    case EDIT_CURRENT_USER_FAILURE:
      return {
        ...state,
        ...action,
      };

    default:
      return state;
  }
};

export type { UserState };
export default reducer;
