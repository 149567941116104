import type { LogLine } from '../types/logs';
import {
  EXECUTION_LOG_STREAM_START,
  EXECUTION_LOG_STREAM_UPDATE,
  EXECUTION_LOG_STREAM_CLOSE,
  SYSTEM_LOG_STREAM_START,
  SYSTEM_LOG_STREAM_UPDATE,
  SYSTEM_LOG_STREAM_CLOSE,
} from '../constants';

export type LogsState = {
  systemLogs: LogLine[],
  systemLogSource: EventSource | null,
  systemLogStreaming: boolean,
  executionLogs: LogLine[],
  executionLogSource: EventSource | null,
  executionLogsStreaming: boolean,
};

const LOG_COUNT_LIMIT = 250;

// Initial State
const initialState: LogsState = {
  systemLogs: [],
  systemLogSource: null,
  systemLogStreaming: false,
  executionLogs: [],
  executionLogSource: null,
  executionLogsStreaming: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case EXECUTION_LOG_STREAM_UPDATE:
      return {
        ...state,
        executionLogs: [
          action.logLine,
          ...state.executionLogs.slice(0, LOG_COUNT_LIMIT - 1),
        ],
      };
    case EXECUTION_LOG_STREAM_START:
      return {
        ...state,
        executionLogs: [],
        executionLogSource: action.logSource,
        executionLogsStreaming: true,
      };
    case EXECUTION_LOG_STREAM_CLOSE:
      return {
        ...state,
        executionLogSource: null,
        executionLogsStreaming: false,
      };
    case SYSTEM_LOG_STREAM_UPDATE:
      return {
        ...state,
        systemLogs: [
          action.logLine,
          ...state.systemLogs.slice(0, LOG_COUNT_LIMIT - 1),
        ],
      };
    case SYSTEM_LOG_STREAM_START:
      return {
        ...state,
        systemLogs: [],
        systemLogSource: action.logSource,
        systemLogsStreaming: true,
      };
    case SYSTEM_LOG_STREAM_CLOSE:
      return {
        ...state,
        systemLogs: [],
        systemLogSource: null,
        systemLogsStreaming: false,
      };
    default:
      return state;
  }
};

export default reducer;
