import { merge, of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { combineReducers } from 'redux';
import type { AnyAction, Reducer } from 'redux';
import { normalize } from 'normalizr';
import { stringify as qsStringify } from 'query-string';
import { ofType } from 'redux-observable';
import type { Epic } from 'redux-observable';
import { keyBy, omit, sortedUniq, uniq } from 'lodash';
import { arrayOfSharedFields } from 'common/types/sharedObjects';
import { createRxFetch } from 'common/util/createFetch';
import type {
  ObjectType,
  SharedConfig,
  SharedField,
  SharedObject,
  SharedObjectVersion,
} from 'common/types/sharedObjects';
import type { Entities } from '../types/parameters';
import type { ErrorResponse } from '../types/api';
import { SET_CURRENT_PROJECT } from '../constants';
import * as actionTypes from '../constants/sharedObjects';
import { camelCaseKeys, snakeCaseKeys } from '../util/strings';

interface Detail extends SharedObject {
  sharedObjectTypeUuid: string;
  parameters: {};
}

type ObjectState<E = SharedObject> = {
  detail: Detail,
  typeFilter: string,
  ids: Array<string>,
  entities: Entities<E>,
  isFetching: boolean,
  isSubmitting: boolean,
  isFetchingDetail: boolean,
  fetched: { [key: string]: boolean },
};

type ConfigState = ObjectState<SharedConfig>;
type FieldState = ObjectState<SharedField>;

type ObjectTypeState<E = ObjectType> = {
  ids: Array<string>,
  isFetching: boolean,
  error: ErrorResponse,
  entities: Entities<E>,
  initialValues: Entities<E>,
};

type ConfigTypeState = ObjectTypeState<ObjectType>;
type FieldTypeState = ObjectTypeState<ObjectType>;

type VersionState = {
  ids: number[],
  entities: Entities<SharedObjectVersion>,
  error: ErrorResponse,
  isFetching: boolean,
};

export type SharedObjectsState = {
  detail: any,
  objects: ObjectState,
  objectTypes: ObjectTypeState,
  configs: ConfigState,
  configTypes: ConfigTypeState,
  fields: FieldState,
  fieldTypes: FieldTypeState,
  versions: VersionState,
};

type ConfigReducer = Reducer<ConfigState, AnyAction>;
type ObjectsReducer = Reducer<ObjectState, AnyAction>;
type FieldsReducer = Reducer<FieldState, AnyAction>;

type ConfigTypeReducer = Reducer<ConfigTypeState, AnyAction>;
type FieldTypeReducer = Reducer<FieldTypeState, AnyAction>;
type ObjectTypeReducer = Reducer<ObjectTypeState, AnyAction>;

type VersionReducer = Reducer<VersionState, AnyAction>;

// Initial State
const initialObjectState = {
  ids: [],
  entities: {},
  fetched: {},
  detail: {},
  isFetching: false,
  isSubmitting: false,
  isFetchingDetail: false,
  typeFilter: null,
};

const objects: ObjectsReducer = (state = initialObjectState, action) => {
  switch (action.type) {
    case SET_CURRENT_PROJECT:
      return initialObjectState;

    case actionTypes.LOAD_SHARED_OBJECT_REQUEST:
    case actionTypes.LOAD_SHARED_OBJECTS_REQUEST:
      return { ...state, isFetching: true, error: null };

    case actionTypes.LOAD_SHARED_OBJECTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        entities: {
          ...state.entities,
          ...action.payload.entities.sharedObjects,
        },
        ids: uniq([...state.ids, ...action.payload.ids]),
      };

    case actionTypes.UPDATE_SHARED_OBJECT_SUCCESS:
    case actionTypes.CREATE_SHARED_OBJECT_SUCCESS:
    case actionTypes.LOAD_SHARED_OBJECT_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.entities.sharedObjects,
        },
        ids: uniq([...state.ids, action.payload.uuid]),
        isFetching: false,
        isSubmitting: false,
      };

    case actionTypes.CREATE_SHARED_OBJECT_REQUEST:
    case actionTypes.UPDATE_SHARED_OBJECT_REQUEST:
    case actionTypes.DELETE_SHARED_OBJECT_REQUEST:
      return { ...state, isSubmitting: true };

    case actionTypes.CREATE_SHARED_OBJECT_FAILURE:
    case actionTypes.UPDATE_SHARED_OBJECT_FAILURE:
    case actionTypes.DELETE_SHARED_OBJECT_FAILURE:
      return {
        ...state,
        error: action.error,
        timestamp: action.timestamp,
        isSubmitting: false,
      };

    case actionTypes.DELETE_SHARED_OBJECT_SUCCESS:
      return {
        ...state,
        entities: omit(state.entities, action.payload.uuid),
        ids: state.ids.filter((uuid) => uuid !== action.payload.uuid),
        isSubmitting: false,
      };

    case actionTypes.OBJECT_DETAIL_REQUEST:
      return { ...state, isFetchingDetail: true };

    case actionTypes.OBJECT_DETAIL_FAILURE:
      return {
        ...state,
        error: action.error,
        timestamp: action.timestamp,
        isFetchingDetail: false,
      };

    case actionTypes.OBJECT_DETAIL_SUCCESS:
      return { ...state, isFetchingDetail: false, detail: action.payload };

    case actionTypes.CLEAR_OBJECT_DETAIL:
      return { ...state, detail: {} };
    default:
      return state;
  }
};

const initialTypeState = {
  ids: [],
  entities: {},
  initialValues: {},
  isFetching: false,
};

const objectTypes: ObjectTypeReducer = (state = initialTypeState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SHARED_OBJECT_TYPE_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.LOAD_SHARED_OBJECT_TYPE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        sharedObjectTypes: action.sharedObjectTypes,
      };

    case actionTypes.LOAD_SHARED_OBJECT_TYPES_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.LOAD_SHARED_OBJECT_TYPES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        timestamp: action.timestamp,
      };

    case actionTypes.LOAD_SHARED_OBJECT_TYPES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        entities: {
          ...state.entities,
          ...action.payload.entities.sharedObjectTypes,
        },
        ids: uniq([...state.ids, ...action.payload.ids]),
      };

    case actionTypes.SELECT_OBJECT_TYPE:
      return {
        ...state,
        detail: state.entities[action.payload.uuid],
        isFetching: false,
      };

    default:
      return state;
  }
};

const configs: ConfigReducer = (state = initialObjectState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SHARED_CONFIG_REQUEST:
    case actionTypes.LOAD_SHARED_CONFIGS_REQUEST:
      return { ...state, error: null, isFetching: true };

    case actionTypes.LOAD_SHARED_CONFIG_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        timestamp: action.timestamp,
      };

    case actionTypes.LOAD_SHARED_CONFIGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        entities: {
          ...state.entities,
          ...action.payload.entities.sharedConfigs,
        },
        ids: uniq([...state.ids, ...action.payload.ids]),
      };

    case actionTypes.UPDATE_SHARED_CONFIG_SUCCESS:
    case actionTypes.CREATE_SHARED_CONFIG_SUCCESS:
    case actionTypes.LOAD_SHARED_CONFIG_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.entities.sharedConfigs,
        },
        ids: uniq([...state.ids, action.payload.uuid]),
        isFetching: false,
        isSubmitting: false,
      };

    case actionTypes.CREATE_SHARED_CONFIG_REQUEST:
    case actionTypes.UPDATE_SHARED_CONFIG_REQUEST:
    case actionTypes.DELETE_SHARED_CONFIG_REQUEST:
      return { ...state, isSubmitting: true, error: null };

    case actionTypes.CREATE_SHARED_CONFIG_FAILURE:
    case actionTypes.UPDATE_SHARED_CONFIG_FAILURE:
    case actionTypes.DELETE_SHARED_CONFIG_FAILURE:
      return {
        ...state,
        error: action.error,
        timestamp: action.timestamp,
        isSubmitting: false,
      };

    case actionTypes.DELETE_SHARED_CONFIG_SUCCESS:
      return {
        ...state,
        entities: omit(state.entities, action.payload.uuid),
        ids: state.ids.filter((uuid) => uuid !== action.payload.uuid),
        isSubmitting: false,
      };

    case actionTypes.CONFIG_DETAIL_REQUEST:
      return { ...state, isFetchingDetail: true, error: null };

    case actionTypes.CONFIG_DETAIL_FAILURE:
      return {
        ...state,
        error: action.error,
        timestamp: action.timestamp,
        isFetchingDetail: false,
      };

    case actionTypes.CONFIG_DETAIL_SUCCESS:
      return { ...state, isFetchingDetail: false, detail: action.payload };

    case actionTypes.CLEAR_CONFIG_DETAIL:
      return { ...state, detail: {}, error: null };
    default:
      return state;
  }
};

const fields: FieldsReducer = (state = initialObjectState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SHARED_FIELD_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
        fetched: { ...state.fetched, [action.payload.uuid]: true },
      };

    case actionTypes.LOAD_SHARED_FIELDS_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
      };

    case actionTypes.LOAD_SHARED_FIELD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        timestamp: action.timestamp,
      };

    case actionTypes.LOAD_SHARED_FIELDS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        entities: {
          ...state.entities,
          ...action.payload.entities.sharedFields,
        },
        ids: uniq([...state.ids, ...action.payload.ids]),
        fetched: Object.fromEntries(
          [...state.ids, ...action.payload.ids].map((i) => [i, true]),
        ),
      };

    case actionTypes.UPDATE_SHARED_FIELD_SUCCESS:
    case actionTypes.CREATE_SHARED_FIELD_SUCCESS:
    case actionTypes.LOAD_SHARED_FIELD_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.entities.sharedFields,
        },
        ids: uniq([...state.ids, action.payload.uuid]),
        isFetching: false,
        isSubmitting: false,
      };

    case actionTypes.CREATE_SHARED_FIELD_REQUEST:
    case actionTypes.UPDATE_SHARED_FIELD_REQUEST:
    case actionTypes.DELETE_SHARED_FIELD_REQUEST:
      return { ...state, isSubmitting: true, error: null };

    case actionTypes.CREATE_SHARED_FIELD_FAILURE:
    case actionTypes.UPDATE_SHARED_FIELD_FAILURE:
    case actionTypes.DELETE_SHARED_FIELD_FAILURE:
      return {
        ...state,
        error: action.error,
        timestamp: action.timestamp,
        isSubmitting: false,
      };

    case actionTypes.DELETE_SHARED_FIELD_SUCCESS:
      return {
        ...state,
        entities: omit(state.entities, action.payload.uuid),
        ids: state.ids.filter((uuid) => uuid !== action.payload.uuid),
        isSubmitting: false,
      };

    case actionTypes.FIELD_DETAIL_REQUEST:
      return { ...state, isFetchingDetail: true, error: null };

    case actionTypes.FIELD_DETAIL_FAILURE:
      return {
        ...state,
        error: action.error,
        timestamp: action.timestamp,
        isFetchingDetail: false,
      };

    case actionTypes.FIELD_DETAIL_SUCCESS:
      return { ...state, isFetchingDetail: false, detail: action.payload };

    case actionTypes.CLEAR_FIELD_DETAIL:
      return { ...state, detail: {}, error: null };

    case actionTypes.CLEAR_FIELD_TYPE:
      return { ...state, typeFilter: null };

    case actionTypes.SELECT_FIELD_TYPE:
      return { ...state, typeFilter: action.payload };

    default:
      return state;
  }
};

const configTypes: ConfigTypeReducer = (state = initialTypeState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SHARED_CONFIG_TYPE_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.LOAD_SHARED_CONFIG_TYPE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        sharedObjectTypes: action.sharedConfigTypes,
      };

    case actionTypes.LOAD_SHARED_CONFIG_TYPES_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.LOAD_SHARED_CONFIG_TYPES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        timestamp: action.timestamp,
      };

    case actionTypes.LOAD_SHARED_CONFIG_TYPES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        entities: {
          ...state.entities,
          ...action.payload.entities.sharedConfigTypes,
        },
        ids: uniq([...state.ids, ...action.payload.ids]),
        initialValues: {
          ...state.initialValues,
          ...action.payload.initialValues,
        },
      };

    case actionTypes.SELECT_CONFIG_TYPE:
      return {
        ...state,
        detail: state.entities[action.payload.uuid],
        isFetching: false,
      };

    default:
      return state;
  }
};

const fieldTypes: FieldTypeReducer = (state = initialTypeState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SHARED_FIELD_TYPE_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.LOAD_SHARED_FIELD_TYPE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        sharedObjectTypes: action.sharedFieldTypes,
      };

    case actionTypes.LOAD_SHARED_FIELD_TYPES_REQUEST:
      return { ...state, isFetching: true };

    case actionTypes.LOAD_SHARED_FIELD_TYPES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        timestamp: action.timestamp,
      };

    case actionTypes.LOAD_SHARED_FIELD_TYPES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        entities: {
          ...state.entities,
          ...action.payload.entities.sharedFieldTypes,
        },
        initialValues: {
          ...state.initialValues,
          ...action.payload.initialValues,
        },
        ids: uniq([...state.ids, ...action.payload.ids]),
      };

    case actionTypes.SELECT_FIELD_TYPE:
      return {
        ...state,
        detail: state.entities[action.payload.uuid],
        isFetching: false,
      };

    default:
      return state;
  }
};

const initialVersionState: VersionState = {
  ids: [],
  entities: {},
  error: null,
  isFetching: false,
};

const versions: VersionReducer = (state = initialVersionState, action = {}) => {
  switch (action.type) {
    case actionTypes.SHARED_OBJECT_VERSIONS_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
      };

    case actionTypes.SHARED_OBJECT_VERSIONS_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };

    case actionTypes.SHARED_OBJECT_VERSIONS_CANCELLED:
      return {
        ...state,
        isFetching: false,
      };

    case actionTypes.SHARED_OBJECT_VERSIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        entities: {
          ...state.entities,
          [action.payload.uuid]: action.payload.entities,
        },
        ids: sortedUniq(
          state.ids.concat(action.payload.ids).sort((a, b) => b - a),
        ),
      };

    default:
      return state;
  }
};

const detail = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SELECT_OBJECT_TYPE:
      return {
        ...state,
        [action.payload.uuid]: objectTypes(state[action.payload.uuid], action),
      };

    default:
      return state;
  }
};

// SHARED_OBJECT_VERSIONS
// --------------------------------------------------------

function fetchFieldEpic(action$) {
  return merge(
    action$.pipe(
      ofType(actionTypes.LOAD_SHARED_FIELDS_REQUEST),
      mergeMap((action) => {
        const { payload: params } = action;
        const query = qsStringify(snakeCaseKeys(params));

        return createRxFetch({ url: `/api/v2/shared-fields?${query}` }).pipe(
          map(({ data }) => {
            const { entities, result: ids } = normalize(
              camelCaseKeys(data),
              arrayOfSharedFields,
            );
            return {
              type: actionTypes.LOAD_SHARED_FIELDS_SUCCESS,
              payload: {
                ids,
                entities,
              },
            };
          }),
          takeUntil(
            action$.pipe(ofType(actionTypes.FETCH_SHARED_FIELDS_CANCELLED)),
          ),
          catchError((err: any) => {
            const { response: payload } = err;
            return of({
              type: actionTypes.LOAD_SHARED_FIELD_FAILURE,
              payload,
              error: true,
            });
          }),
        );
      }),
    ),
  );
}

const objectVersionEpic: Epic = (action$, state$, { currentProject }) => {
  return action$.pipe(
    ofType(actionTypes.SHARED_OBJECT_VERSIONS_REQUEST),
    switchMap((action) =>
      createRxFetch({
        method: 'get',
        url: `/api/v2/projects/${
          action.payload.projectUuid || currentProject(state$.value)
        }/shared-objects/${action.payload.uuid}/versions`,
      })
        .pipe(
          map(({ data }) => {
            return data.map((o, ix) => ({ ...o, version: ix + 1 }));
          }),
        )
        .pipe(
          map((data) => {
            const ids = data.map(({ transactionId }) => transactionId);
            const entities = keyBy(data, 'transactionId');
            return {
              type: actionTypes.SHARED_OBJECT_VERSIONS_SUCCESS,
              payload: {
                ids,
                entities,
                uuid: action.payload.uuid,
              },
            };
          }),
          catchError((error) =>
            of({
              type: actionTypes.SHARED_OBJECT_VERSIONS_REJECTED,
              payload: error,
              error: true,
            }),
          ),
          takeUntil(
            action$.pipe(ofType(actionTypes.SHARED_OBJECT_VERSIONS_CANCELLED)),
          ),
        ),
    ),
  );
};

export { fetchFieldEpic, objectVersionEpic };
export type { FieldState, FieldTypeState, ConfigState, ConfigTypeState };
export default combineReducers<SharedObjectsState>({
  configs,
  configTypes,
  fields,
  fieldTypes,
  objects,
  objectTypes,
  detail,
  versions,
});
