/*
 * ------------------------------------------------------------------------------
 * Shared Config
 * ------------------------------------------------------------------------------
 * */
const SELECT_CONFIG_TYPE = 'SELECT_CONFIG_TYPE';
const CLEAR_CONFIG_DETAIL = 'CLEAR_CONFIG_DETAIL';

const CONFIG_DETAIL_REQUEST = 'CONFIG_DETAIL_REQUEST';
const CONFIG_DETAIL_FAILURE = 'CONFIG_DETAIL_FAILURE';
const CONFIG_DETAIL_SUCCESS = 'CONFIG_DETAIL_SUCCESS';

const LOAD_SHARED_CONFIG_REQUEST = 'LOAD_SHARED_CONFIG_REQUEST';
const LOAD_SHARED_CONFIG_FAILURE = 'LOAD_SHARED_CONFIG_FAILURE';
const LOAD_SHARED_CONFIG_SUCCESS = 'LOAD_SHARED_CONFIG_SUCCESS';
const LOAD_SHARED_CONFIGS_REQUEST = 'LOAD_SHARED_CONFIGS_REQUEST';
const LOAD_SHARED_CONFIGS_FAILURE = 'LOAD_SHARED_CONFIGS_FAILURE';
const LOAD_SHARED_CONFIGS_SUCCESS = 'LOAD_SHARED_CONFIGS_SUCCESS';

const CREATE_SHARED_CONFIG_SUCCESS = 'CREATE_SHARED_CONFIG_SUCCESS';
const CREATE_SHARED_CONFIG_REQUEST = 'CREATE_SHARED_CONFIG_REQUEST';
const CREATE_SHARED_CONFIG_FAILURE = 'CREATE_SHARED_CONFIG_FAILURE';

const UPDATE_SHARED_CONFIG_SUCCESS = 'UPDATE_SHARED_CONFIG_SUCCESS';
const UPDATE_SHARED_CONFIG_REQUEST = 'UPDATE_SHARED_CONFIG_REQUEST';
const UPDATE_SHARED_CONFIG_FAILURE = 'UPDATE_SHARED_CONFIG_FAILURE';

const DELETE_SHARED_CONFIG_REQUEST = 'DELETE_SHARED_CONFIG_REQUEST';
const DELETE_SHARED_CONFIG_FAILURE = 'DELETE_SHARED_CONFIG_FAILURE';
const DELETE_SHARED_CONFIG_SUCCESS = 'DELETE_SHARED_CONFIG_SUCCESS';

const LOAD_SHARED_CONFIG_TYPE_REQUEST = 'LOAD_SHARED_CONFIG_TYPE_REQUEST';
const LOAD_SHARED_CONFIG_TYPE_FAILURE = 'LOAD_SHARED_CONFIG_TYPE_FAILURE';
const LOAD_SHARED_CONFIG_TYPE_SUCCESS = 'LOAD_SHARED_CONFIG_TYPE_SUCCESS';
const LOAD_SHARED_CONFIG_TYPES_REQUEST = 'LOAD_SHARED_CONFIG_TYPES_REQUEST';
const LOAD_SHARED_CONFIG_TYPES_FAILURE = 'LOAD_SHARED_CONFIG_TYPES_FAILURE';
const LOAD_SHARED_CONFIG_TYPES_SUCCESS = 'LOAD_SHARED_CONFIG_TYPES_SUCCESS';
const SHARED_CONFIG_VERSIONS_REQUEST = 'SHARED_CONFIG_VERSIONS_REQUEST';
const SHARED_CONFIG_VERSIONS_SUCCESS = 'SHARED_CONFIG_VERSIONS_SUCCESS';
const SHARED_CONFIG_VERSIONS_REJECTED = 'SHARED_CONFIG_VERSIONS_REJECTED';
const SHARED_CONFIG_VERSIONS_CANCELLED = 'SHARED_CONFIG_VERSIONS_CANCELLED';

/*
 * ------------------------------------------------------------------------------
 * Shared Field
 * ------------------------------------------------------------------------------
 * */
const SELECT_FIELD_TYPE = 'SELECT_FIELD_TYPE';
const CLEAR_FIELD_TYPE = 'CLEAR_FIELD_TYPE';
const FETCH_SHARED_CONFIGS_CANCELLED = 'FETCH_SHARED_CONFIGS_CANCELLED';

const CLEAR_FIELD_DETAIL = 'CLEAR_FIELD_DETAIL';
const OBJECT_DETAIL_REQUEST = 'OBJECT_DETAIL_REQUEST';
const OBJECT_DETAIL_FAILURE = 'OBJECT_DETAIL_FAILURE';
const OBJECT_DETAIL_SUCCESS = 'OBJECT_DETAIL_SUCCESS';

const LOAD_SHARED_FIELD_REQUEST = 'LOAD_SHARED_FIELD_REQUEST';
const LOAD_SHARED_FIELD_FAILURE = 'LOAD_SHARED_FIELD_FAILURE';
const LOAD_SHARED_FIELD_SUCCESS = 'LOAD_SHARED_FIELD_SUCCESS';

const LOAD_SHARED_FIELDS_BY_TYPE = 'LOAD_SHARED_FIELDS_BY_TYPE';
const LOAD_SHARED_FIELDS_REQUEST = 'LOAD_SHARED_FIELDS_REQUEST';
const LOAD_SHARED_FIELDS_FAILURE = 'LOAD_SHARED_FIELDS_FAILURE';
const LOAD_SHARED_FIELDS_SUCCESS = 'LOAD_SHARED_FIELDS_SUCCESS';

const CREATE_SHARED_FIELD_SUCCESS = 'CREATE_SHARED_FIELD_SUCCESS';
const CREATE_SHARED_FIELD_REQUEST = 'CREATE_SHARED_FIELD_REQUEST';
const CREATE_SHARED_FIELD_FAILURE = 'CREATE_SHARED_FIELD_FAILURE';

const FIELD_DETAIL_REQUEST = 'FIELD_DETAIL_REQUEST';
const FIELD_DETAIL_FAILURE = 'FIELD_DETAIL_FAILURE';
const FIELD_DETAIL_SUCCESS = 'FIELD_DETAIL_SUCCESS';

const UPDATE_SHARED_FIELD_SUCCESS = 'UPDATE_SHARED_FIELD_SUCCESS';
const UPDATE_SHARED_FIELD_REQUEST = 'UPDATE_SHARED_FIELD_REQUEST';
const UPDATE_SHARED_FIELD_FAILURE = 'UPDATE_SHARED_FIELD_FAILURE';

const DELETE_SHARED_FIELD_REQUEST = 'DELETE_SHARED_FIELD_REQUEST';
const DELETE_SHARED_FIELD_FAILURE = 'DELETE_SHARED_FIELD_FAILURE';
const DELETE_SHARED_FIELD_SUCCESS = 'DELETE_SHARED_FIELD_SUCCESS';

const LOAD_SHARED_FIELD_TYPE_REQUEST = 'LOAD_SHARED_FIELD_TYPE_REQUEST';
const LOAD_SHARED_FIELD_TYPE_FAILURE = 'LOAD_SHARED_FIELD_TYPE_FAILURE';
const LOAD_SHARED_FIELD_TYPE_SUCCESS = 'LOAD_SHARED_FIELD_TYPE_SUCCESS';
const LOAD_SHARED_FIELD_TYPES_REQUEST = 'LOAD_SHARED_FIELD_TYPES_REQUEST';
const LOAD_SHARED_FIELD_TYPES_FAILURE = 'LOAD_SHARED_FIELD_TYPES_FAILURE';
const LOAD_SHARED_FIELD_TYPES_SUCCESS = 'LOAD_SHARED_FIELD_TYPES_SUCCESS';

const FETCH_SHARED_FIELDS_CANCELLED = 'FETCH_SHARED_FIELDS_CANCELLED';
const SHARED_FIELD_VERSIONS_REQUEST = 'SHARED_FIELD_VERSIONS_REQUEST';
const SHARED_FIELD_VERSIONS_SUCCESS = 'SHARED_FIELD_VERSIONS_SUCCESS';
const SHARED_FIELD_VERSIONS_REJECTED = 'SHARED_FIELD_VERSIONS_REJECTED';
const SHARED_FIELD_VERSIONS_CANCELLED = 'SHARED_FIELD_VERSIONS_CANCELLED';

/*
 * ------------------------------------------------------------------------------
 * Shared Object
 * ------------------------------------------------------------------------------
 * */
const SELECT_OBJECT_TYPE = 'SELECT_OBJECT_TYPE';
const CLEAR_OBJECT_DETAIL = 'CLEAR_OBJECT_DETAIL';

const LOAD_SHARED_OBJECT_REQUEST = 'LOAD_SHARED_OBJECT_REQUEST';
const LOAD_SHARED_OBJECT_FAILURE = 'LOAD_SHARED_OBJECT_FAILURE';
const LOAD_SHARED_OBJECT_SUCCESS = 'LOAD_SHARED_OBJECT_SUCCESS';
const LOAD_SHARED_OBJECTS_REQUEST = 'LOAD_SHARED_OBJECTS_REQUEST';
const LOAD_SHARED_OBJECTS_FAILURE = 'LOAD_SHARED_OBJECTS_FAILURE';
const LOAD_SHARED_OBJECTS_SUCCESS = 'LOAD_SHARED_OBJECTS_SUCCESS';

const CREATE_SHARED_OBJECT_SUCCESS = 'CREATE_SHARED_OBJECT_SUCCESS';
const CREATE_SHARED_OBJECT_REQUEST = 'CREATE_SHARED_OBJECT_REQUEST';
const CREATE_SHARED_OBJECT_FAILURE = 'CREATE_SHARED_OBJECT_FAILURE';

const UPDATE_SHARED_OBJECT_SUCCESS = 'UPDATE_SHARED_OBJECT_SUCCESS';
const UPDATE_SHARED_OBJECT_REQUEST = 'UPDATE_SHARED_OBJECT_REQUEST';
const UPDATE_SHARED_OBJECT_FAILURE = 'UPDATE_SHARED_OBJECT_FAILURE';

const DELETE_SHARED_OBJECT_REQUEST = 'DELETE_SHARED_OBJECT_REQUEST';
const DELETE_SHARED_OBJECT_FAILURE = 'DELETE_SHARED_OBJECT_FAILURE';
const DELETE_SHARED_OBJECT_SUCCESS = 'DELETE_SHARED_OBJECT_SUCCESS';

const LOAD_SHARED_OBJECT_TYPE_REQUEST = 'LOAD_SHARED_OBJECT_TYPE_REQUEST';
const LOAD_SHARED_OBJECT_TYPE_FAILURE = 'LOAD_SHARED_OBJECT_TYPE_FAILURE';
const LOAD_SHARED_OBJECT_TYPE_SUCCESS = 'LOAD_SHARED_OBJECT_TYPE_SUCCESS';
const LOAD_SHARED_OBJECT_TYPES_REQUEST = 'LOAD_SHARED_OBJECT_TYPES_REQUEST';
const LOAD_SHARED_OBJECT_TYPES_FAILURE = 'LOAD_SHARED_OBJECT_TYPES_FAILURE';
const LOAD_SHARED_OBJECT_TYPES_SUCCESS = 'LOAD_SHARED_OBJECT_TYPES_SUCCESS';

const SHARED_OBJECT_VERSIONS_REQUEST = 'SHARED_OBJECT_VERSIONS_REQUEST';
const SHARED_OBJECT_VERSIONS_SUCCESS = 'SHARED_OBJECT_VERSIONS_SUCCESS';
const SHARED_OBJECT_VERSIONS_REJECTED = 'SHARED_OBJECT_VERSIONS_REJECTED';
const SHARED_OBJECT_VERSIONS_CANCELLED = 'SHARED_OBJECT_VERSIONS_CANCELLED';

export {
  SELECT_CONFIG_TYPE,
  CLEAR_CONFIG_DETAIL,
  CONFIG_DETAIL_REQUEST,
  CONFIG_DETAIL_FAILURE,
  CONFIG_DETAIL_SUCCESS,
  LOAD_SHARED_CONFIG_REQUEST,
  LOAD_SHARED_CONFIG_FAILURE,
  LOAD_SHARED_CONFIG_SUCCESS,
  LOAD_SHARED_CONFIGS_REQUEST,
  LOAD_SHARED_CONFIGS_FAILURE,
  LOAD_SHARED_CONFIGS_SUCCESS,
  CREATE_SHARED_CONFIG_SUCCESS,
  CREATE_SHARED_CONFIG_REQUEST,
  CREATE_SHARED_CONFIG_FAILURE,
  UPDATE_SHARED_CONFIG_SUCCESS,
  UPDATE_SHARED_CONFIG_REQUEST,
  UPDATE_SHARED_CONFIG_FAILURE,
  DELETE_SHARED_CONFIG_REQUEST,
  DELETE_SHARED_CONFIG_FAILURE,
  DELETE_SHARED_CONFIG_SUCCESS,
  LOAD_SHARED_CONFIG_TYPE_REQUEST,
  LOAD_SHARED_CONFIG_TYPE_FAILURE,
  LOAD_SHARED_CONFIG_TYPE_SUCCESS,
  LOAD_SHARED_CONFIG_TYPES_REQUEST,
  LOAD_SHARED_CONFIG_TYPES_FAILURE,
  LOAD_SHARED_CONFIG_TYPES_SUCCESS,
  SHARED_CONFIG_VERSIONS_REQUEST,
  SHARED_CONFIG_VERSIONS_SUCCESS,
  SHARED_CONFIG_VERSIONS_REJECTED,
  SHARED_CONFIG_VERSIONS_CANCELLED,
  FETCH_SHARED_FIELDS_CANCELLED,
  CLEAR_FIELD_TYPE,
  SELECT_FIELD_TYPE,
  FETCH_SHARED_CONFIGS_CANCELLED,
  CLEAR_FIELD_DETAIL,
  OBJECT_DETAIL_REQUEST,
  OBJECT_DETAIL_FAILURE,
  OBJECT_DETAIL_SUCCESS,
  LOAD_SHARED_FIELD_REQUEST,
  LOAD_SHARED_FIELD_FAILURE,
  LOAD_SHARED_FIELD_SUCCESS,
  LOAD_SHARED_FIELDS_REQUEST,
  LOAD_SHARED_FIELDS_FAILURE,
  LOAD_SHARED_FIELDS_SUCCESS,
  CREATE_SHARED_FIELD_SUCCESS,
  CREATE_SHARED_FIELD_REQUEST,
  CREATE_SHARED_FIELD_FAILURE,
  FIELD_DETAIL_REQUEST,
  FIELD_DETAIL_FAILURE,
  FIELD_DETAIL_SUCCESS,
  UPDATE_SHARED_FIELD_SUCCESS,
  UPDATE_SHARED_FIELD_REQUEST,
  UPDATE_SHARED_FIELD_FAILURE,
  DELETE_SHARED_FIELD_REQUEST,
  DELETE_SHARED_FIELD_FAILURE,
  DELETE_SHARED_FIELD_SUCCESS,
  LOAD_SHARED_FIELD_TYPE_REQUEST,
  LOAD_SHARED_FIELD_TYPE_FAILURE,
  LOAD_SHARED_FIELD_TYPE_SUCCESS,
  LOAD_SHARED_FIELD_TYPES_REQUEST,
  LOAD_SHARED_FIELD_TYPES_FAILURE,
  LOAD_SHARED_FIELD_TYPES_SUCCESS,
  SHARED_FIELD_VERSIONS_REQUEST,
  SHARED_FIELD_VERSIONS_SUCCESS,
  SHARED_FIELD_VERSIONS_REJECTED,
  SHARED_FIELD_VERSIONS_CANCELLED,
  SELECT_OBJECT_TYPE,
  CLEAR_OBJECT_DETAIL,
  LOAD_SHARED_OBJECT_REQUEST,
  LOAD_SHARED_OBJECT_FAILURE,
  LOAD_SHARED_FIELDS_BY_TYPE,
  LOAD_SHARED_OBJECT_SUCCESS,
  LOAD_SHARED_OBJECTS_REQUEST,
  LOAD_SHARED_OBJECTS_FAILURE,
  LOAD_SHARED_OBJECTS_SUCCESS,
  CREATE_SHARED_OBJECT_SUCCESS,
  CREATE_SHARED_OBJECT_REQUEST,
  CREATE_SHARED_OBJECT_FAILURE,
  UPDATE_SHARED_OBJECT_SUCCESS,
  UPDATE_SHARED_OBJECT_REQUEST,
  UPDATE_SHARED_OBJECT_FAILURE,
  DELETE_SHARED_OBJECT_REQUEST,
  DELETE_SHARED_OBJECT_FAILURE,
  DELETE_SHARED_OBJECT_SUCCESS,
  LOAD_SHARED_OBJECT_TYPE_REQUEST,
  LOAD_SHARED_OBJECT_TYPE_FAILURE,
  LOAD_SHARED_OBJECT_TYPE_SUCCESS,
  LOAD_SHARED_OBJECT_TYPES_REQUEST,
  LOAD_SHARED_OBJECT_TYPES_FAILURE,
  LOAD_SHARED_OBJECT_TYPES_SUCCESS,
  SHARED_OBJECT_VERSIONS_REQUEST,
  SHARED_OBJECT_VERSIONS_SUCCESS,
  SHARED_OBJECT_VERSIONS_REJECTED,
  SHARED_OBJECT_VERSIONS_CANCELLED,
};
