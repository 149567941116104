import { has, merge, at, omit } from 'lodash';

import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import axios from 'axios';
import observableAxios from 'axios-observable';
import type { AxiosRequestConfig } from 'axios';
import type { AxiosObservable } from 'axios-observable';

// import jwt from 'jsonwebtoken';
// import qs from 'query-string';
import { camelCaseKeys, snakeCaseKeys } from './strings';

const ERROR_LOCATIONS = Object.freeze([
  'querystring',
  'query',
  'json',
  'json_or_form',
  'form',
  'headers',
  'cookies',
  'files',
]);
export const observableClient = observableAxios.create();
export const client = axios.create();

const snakeCaseInterceptor = (config) => {
  const newConfig = { ...config };
  if (newConfig.headers['Content-Type'] === 'multipart/form-data')
    return newConfig;
  if (config.data) {
    newConfig.data = snakeCaseKeys(config.data);
  }
  return newConfig;
};
client.interceptors.request.use(snakeCaseInterceptor);
observableClient.interceptors.request.use(snakeCaseInterceptor);

// const authInterceptor = async (config) => {
//   const token = localStorage.getItem('id-token');
//   const { payload } = jwt.decode(token, { complete: true });
//   if (Date.now() + 300 * 1000 >= payload.exp * 1000) {
//     const resp = await axios({
//       url: 'https://cognito.staging.nomnomdata.com/oauth2/token',
//       headers: {
//         'content-type': 'application/x-www-form-urlencoded',
//       },
//       method: 'post',
//       data: qs.stringify({
//         grant_type: 'refresh_token',
//         client_id: 'f331bgl8i9oj4akac6scsvie7',
//         refresh_token: localStorage.getItem('refresh-token'),
//       }),
//     });
//     const { data } = resp;
//     localStorage.setItem('id-token', data.id_token);
//     localStorage.setItem('access-token', data.access_token);
//   }
//   const updated = {
//     ...config,
//     headers: {
//       ...config.headers,
//       'cognito-access-token': localStorage.getItem('access-token'),
//       'cognito-id-token': localStorage.getItem('id-token'),
//     },
//   };
//   return updated;
// };
//
// client.interceptors.request.use(authInterceptor);
// observableClient.interceptors.request.use(authInterceptor);

/**
 *
 * Merge nested location errors from flask-smorest
 *
 * @param respData
 * @return {*|{errors: *}}
 */
function mergeNestedErrors(respData) {
  if (has(respData, 'errors')) {
    const errors = merge(
      omit(respData.errors, ...ERROR_LOCATIONS),
      ...at(respData.errors, ...ERROR_LOCATIONS),
    );
    return { ...respData, errors };
  }

  return respData;
}

const defaultOpts = {
  camelize: true,
};

function getAxiosError(axiosResponse: AxiosResponse) {
  const { data, status, statusText } = axiosResponse;
  return {
    errorData: mergeNestedErrors(data),
    error:
      statusText ||
      data.status ||
      'an unknown problem has occurred on the server',
    status,
  };
}

/**
 * Fetch and error handling for nominode API calls
 * @param {AxiosRequestConfig & { camelize: boolean }} request
 * @returns {AxiosObservable}
 */
function createRxFetch(
  request: AxiosRequestConfig & { camelize: boolean },
): AxiosObservable {
  const { camelize, ...requestConfig } = {
    ...defaultOpts,
    ...request,
  };
  return observableClient
    .request({ ...requestConfig, url: `${requestConfig.url}` })
    .pipe(
      switchMap((axiosResponse) => {
        const { data, status } = axiosResponse;
        // eslint-disable-next-line no-param-reassign
        axiosResponse.data = camelize ? camelCaseKeys(data) : data;
        if (status >= 400) {
          throw getAxiosError(axiosResponse);
        }

        return of(axiosResponse);
      }),
      catchError((err: any) => {
        console.error(err);
        const data = camelize ? camelCaseKeys(err.response) : err.response;
        throw getAxiosError(err, data);
      }),
    );
}

/**
 * Fetch and error handling for nominode API calls
 * @param endpoint
 * @param method
 * @param body
 * @param opts
 * @returns {Promise<*>}
 */
async function callApi(
  endpoint: string,
  method: string = 'GET',
  body,
  opts: RequestInit & { camelize?: boolean } = {},
) {
  const { camelize, ...init } = { ...defaultOpts, ...opts };

  try {
    const response = await client.request({
      method,
      url: `${endpoint}`,
      data: method.toLowerCase() === 'get' ? undefined : body,
      ...init,
    });

    const { data } = response;
    if (!(response.status <= 300 && response.status >= 200)) {
      return getAxiosError(response);
    }
    if (camelize) {
      return camelCaseKeys(data);
    }
    return data;
  } catch (e) {
    if (!e.data) {
      e.data = { name: e.name, code: 0, status: e.message };
    }
    return { error: e.message, errorData: e.data, status: e.message };
  }
}

export { createRxFetch, callApi };
export default callApi;
