import { createLogger as reduxLogger, defaults } from 'redux-logger';

// defaults
const DEFAULT_OPTIONS = {
  // if specified this function will be called before each action is processed with this middleware.
  predicate: undefined,
  // takes a Boolean or optionally a Function that receives `getState` function for accessing current store state and `action` object as parameters. Returns `true` if the log group should be collapsed, `false` otherwise.
  collapsed: undefined,
  // `Boolean` print the duration of each action?
  duration: false,
  // `Boolean` print the timestamp with each action?
  timestamp: true,

  // `'log' | 'console' | 'warn' | 'error' | 'info'` console's level
  level: 'log',

  // `ColorsObject` colors for title, prev state, action and next state: https://github.com/LogRocket/redux-logger/blob/master/src/defaults.js#L12-L18
  colors: {
    title: () => 'inherit',
    prevState: () => '#9E9E9E',
    action: () => '#03A9F4',
    nextState: () => '#4CAF50',
    error: () => '#F20404',
  },

  // Format the title used when logging actions.
  titleFormatter: undefined,

  // Transform state before print. Eg. convert Immutable object to plain JSON.
  stateTransformer: defaults.stateTransformer,
  // Transform action before print. Eg. convert Immutable object to plain JSON.
  actionTransformer: defaults.actionTransformer,
  // Transform error before print. Eg. convert Immutable object to plain JSON.
  errorTransformer: defaults.errorTransformer,

  // `LoggerObject` implementation of the `console` API.
  logger: console,
  // `Boolean` should the logger catch, log, and re-throw errors?
  logErrors: true,

  // `Boolean` (alpha) show diff between states?
  diff: false,
  // (alpha) filter function for showing states diff, similar to `predicate`
  diffPredicate: undefined,
};

export default function createLogger(configOverrides) {
  // https://github.com/evgenyrodionov/redux-logger#options
  return reduxLogger({ ...DEFAULT_OPTIONS, ...configOverrides });
}
